import {useEffect, useState} from "react";
import {BreadCrumb} from "../../component/Breadcrumb";
import {UserInput} from "../../component/UserInput";
import {Button} from "../../component/form/Button";
import {getGlobal, globalKeys, showError} from "../../js/main";
import {InputFile} from "../../component/form/InputFile";

export const createFileHandler = (imgSrcSetter, fileSetter) => {
    return (file) => {
        if(file) {
            if(file.type.indexOf("image/") != 0){
                showError("Ungültiges Dateiformat, nur Bilder erlaubt!");
                return;
            }
            let reader = new FileReader();
            reader.onload = (e) => {
                imgSrcSetter(e.target.result);
            }
            reader.readAsDataURL(file);
            fileSetter(file);
        }else{
            fileSetter(null);
            imgSrcSetter(null);
        }
    }
}

export const Edit = ({initialUserInfo, saveAction, submitting, setSubmitting}) => {

    const [userInfo, setUserInfo] = useState(initialUserInfo);
    const [file, setFile] = useState();
    const [imgSrc, setImgSrc] = useState();

    useEffect(() => {
        setUserInfo(initialUserInfo);

        if(initialUserInfo.fileUri){
            setImgSrc(initialUserInfo.fileUri);
        }
    }, [initialUserInfo]);

    return (
        <div>
            <BreadCrumb currentTitle={'Benutzer ' + (userInfo.id !== "" ? 'bearbeiten' : 'hinzufügen')}
                        parentTitle={getGlobal(globalKeys.isAdmin) ? "Benutzer" : null}
                        parentLink="/benutzer"/>

            <div className="default">
                <InputFile label="Bild" name="image" accept="image/*" setter={createFileHandler(setImgSrc, setFile)} maxMBSize={10}/>
                {imgSrc && <img id="preview" className="preview-img" src={imgSrc}/>}
            </div>
            <form onSubmit={e => {
                e.preventDefault();
                setSubmitting(true);
                saveAction(userInfo, file);
            }}>
                <UserInput initialUserInfo={userInfo} setUserFn={setUserInfo}/>

                <div className={"safe-actions"}>
                    <Button key="back" theme="secondary-button mr-1 mb-1" width={230} submitting={submitting} onClick={e => {e.preventDefault(); window.history.back()}}>zurück</Button>
                    <Button key="submit" theme="primary-button mb-1" width={230} submitting={submitting}>speichern</Button>
                </div>
            </form>
        </div>
    );

};