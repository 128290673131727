import {Route, Switch, useHistory} from "react-router-dom";
import {Edit} from "../view/locksystem/Edit.js";
import {Overview} from "../view/locksystem/Overview";
import {get, post} from "../js/api";
import {addDirtyListener, markDirty, showMessage} from "../js/main";
import {useEffect, useState} from "react";
import {UpdateLocksystem} from "../view/locksystem/UpdateLocksystem";
import {getKeysCache} from "../component/func/KeyCache";
import {patchLocksystemKeys, uploadFile} from "../view/myLocksystems/UpdateLocksystem";

export const Locksystem = () => {

    const history = useHistory();

    const [locksystems, setLocksystems] = useState(null);

    const [keys, setKeys] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    const init = () => {
        get("/locksystems").then((response) => {
            setLocksystems(response);
        });
        getKeysCache().then(resp => setKeys(resp));
    }
    useEffect(() => {
        init();
        addDirtyListener(init);
    }, [])

    const createLocksystem = (locksystemInfo, file, locksystemKeysPatch) => {
        post("/locksystems", locksystemInfo).then(async(createdLocksystem) => {
            await uploadFile(createdLocksystem.id, file);
            return createdLocksystem;
        }).then(async (createdLocksystem) => {
            await patchLocksystemKeys(createdLocksystem.id, locksystemKeysPatch);
        }).then(() => {
            markDirty();
            showMessage("Schließsystem gespeichert");
            history.push("/schliessanlagen");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const empty = {
        id: null,
        assetNumber: "",
        sys: "",
        note: "",
        discount: 0,
        keyId: "",
        externalUri: "",
        fileUri: ""
    };

    return (
        <Switch>
            <Route exact path="/schliessanlagen">
                <Overview locksystems={locksystems} keys={keys}/>
            </Route>
            <Route exact path="/schliessanlagen/edit/:locksystemId">
                <UpdateLocksystem locksystems={locksystems} submitting={submitting} setSubmitting={setSubmitting}/>
            </Route>
            <Route exact path="/schliessanlagen/neu">
                <Edit initialSystemInfo={empty} saveAction={createLocksystem} submitting={submitting} setSubmitting={setSubmitting}/>
            </Route>
        </Switch>
    );

};