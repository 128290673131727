import {Kachel} from "../../component/Kachel";
import {ReactComponent as IconKeys} from "../../img/icon/keys.svg";
import {ReactComponent as IconLock} from "../../img/icon/locksystem.svg";
import {ReactComponent as IconOrder} from "../../img/icon/orders.svg";
import {BreadCrumb} from "../../component/Breadcrumb";
import {useEffect, useState} from "react";
import {getOwnUser} from "../../controller/Profile";

export const HomeUser = () => {
    const [welcomeMsg, setWelcomeMsg] = useState("Willkommen!");
    const [imgPath, setImgPath] = useState();
    useEffect(() => {
        getOwnUser().then( u => {
            setWelcomeMsg("Willkommen, "+u.firstName+" "+u.lastName+"!");
            setImgPath(u.fileUri);
        });
    }, []);
    return (
        <div>
            <div className="default welcome-breadcrumb">
                <BreadCrumb currentTitle={welcomeMsg}/>

                {imgPath && <img name="user-img" src={imgPath} />}
            </div>

            <div className="kacheln">
                <Kachel name="Schlüssel bestellen" link="/neue-bestellung">
                    <IconKeys/>
                </Kachel>

                <Kachel name="Meine Bestellungen" link="/meine-bestellungen">
                    <IconOrder/>
                </Kachel>

                <Kachel name="Meine Schließanlagen" link="/meine-schliessanlagen">
                    <IconLock/>
                </Kachel>
            </div>
        </div>
    );

};