import {BreadCrumb} from "../../component/Breadcrumb";

export const AGB = () => {

    return (
        <div>
            <BreadCrumb currentTitle="Allgemeine Geschäftsbedingungen"/>
            <ol className="agbs">
                <li>
                    Sämtliche gegenwärtige und künftige Rechtsbeziehungen zwischen uns und unseren Auftraggebern,
                    Bestellern und sonstigen Vertragspartnern (in der Folge kurz Kunden), insbesondere unsere
                    Verkaufsgeschäfte (Lieferungen und Leistungen), unterliegen ausschließlich diesen unseren
                    allgemeinen Geschäftsbedingungen. Davon abweichende Vereinbarungen bedürfen unserer schriftlichen
                    Zustimmung. Allfällige allgemeine Geschäftsbedingungen des Kunden gelten weder ganz noch teilweise
                    und selbst dann nicht, wenn ihnen im Einzelfall nicht ausdrücklich widersprochen worden ist. Es
                    gelten auch spätere (auch mündliche) Vereinbarungen als zu diesen unseren Geschäftsbedingungen
                    abgeschlossen, und zwar auch ohne gesonderten Hinweis darauf.Für den Fall, dass es sich bei dem
                    Kunden um einen Konsumenten im Sinne des Konsumentenschutzgesetzes (KSchG) handelt, geltend diese
                    unsere Geschäftsbedingungen nur insoweit, als dem nicht zwingende gesetzliche Bestimmungen
                    entgegenstehen. Insbesondere leisten wir – abweichend von Punkt 12 – gegenüber dem Konsumenten
                    Gewähr gemäß den gesetzlichen Bestimmungen.
                </li>
                <li>
                    Unsere Angebote, Preislisten und Kostenvoranschläge sind unverbindlich. Ein Vertrag kommt es durch
                    unsere schriftliche Auftragsbestätigung zustande. Zeichnungsbefugt sind unsererseits der
                    Geschäftsführer und Prokuristen in der laut Firmenbuch ersichtlichen Weise. Unsere
                    Außendienstmitarbeiter sind lediglich inkassoberechtigt und berechtigt, Erklärungen
                    entgegenzunehmen, aber nicht bevollmächtigt, rechtsgeschäftliche Erklärungen abzugeben. Alle
                    Vereinbarungen, Nebenabreden, mündlichen und schriftlichen Zusagen und sonstigen
                    rechtsgeschäftlichen Erklärungen von nicht selbstständig zeichnungsbefugten Vertretern (etwa von
                    unseren Außendienstmitarbeitern) bedürfen zu ihrer Wirksamkeit in jedem Fall der schriftlichen
                    Bestätigung eines zeichnungsbefugten Vertreters und verpflichten uns erst dann. Zustimmungs- bzw.
                    Erklärungsfiktionen im Zusammenhang mit unserem Verhalten sind jedenfalls, auch im Fall einer
                    ständigen Geschäftsbeziehung, ausgeschlossen. Mangels ausdrücklichen gegenteiligen Hinweises sind
                    unsere technischen Angaben und Beschreibungen des Vertragsgegenstands unverbindlich. Der Kunde hat
                    für unvollständige Angaben oder Unterlagen seinerseits einzustehen. Der Kunde hat eine von uns
                    übermittelte Auftragsbestätigung sofort nach Erhalt zu prüfen. Mangels schriftlichen Einspruchs
                    innerhalb von fünf Tagen gelten die darin angeführten Bedingungen sowie die gegenständlichen
                    allgemeinen Geschäftsbedingungen auch dann als vom Kunden vollinhaltlich angenommen, wenn die
                    Auftragsbestätigung geringfügig oder in Nebenpunkten vom Auftrag abweicht. Allenfalls von uns an den
                    Kunden übermittelte Schließpläne sind vom Kunden auf Richtigkeit der Sperreigenschaften, Lagen,
                    Stückzahlen und Ausführung der Komponenten zu überprüfen und gelten mangels gegenteiliger
                    schriftlicher Einwendungen binnen fünf Tagen als akzeptiert. Durch vorbehaltsloses Zustandekommen
                    eines Vertrags verzichtet der Kunde auch auf sämtliche vorvertraglichen Schutz-, Warn- und
                    Aufklärungspflichten unsererseits, soweit uns diesbezüglich nicht nachweislich Vorsatz oder grobe
                    Fahrlässigkeit zur Last fällt. Bei Schlüsseln, welche von uns nach einem vom Kunden beigestellten
                    Muster oder nach den Originalmaßen zum Erstlieferungszeitpunkt angefertigt werden sollen, gilt
                    aufgrund technischer Umstände die tatsächliche Sperrfunktionalität des von uns nachgefertigten
                    Schlüssels bzw. die weitere Sperrfunktionalität von bestehenden Schlüsseln und Zylindern von
                    vornherein nicht als vereinbart, sondern lediglich unser sorgfältiges Bemühen.
                </li>
                <li>
                    Unsere Preise verstehen sich grundsätzlich als Bruttopreise ab Werk. Die vereinbarten Preise kommen
                    sohin zuzüglich gesetzlicher Umsatzsteuer zur Verrechnung. Bei Werkverträgen kann unsererseits eine
                    Abrechnung von Teilleistungen, mangels eindeutig abgrenzbarer Teilleistungen zumindest alle zwei
                    Monate eine Zwischenabrechnung erfolgen. Sofern nicht ausdrücklich etwas anderes vereinbart wurde,
                    werden Auftragsänderungen und Zusatzaufträge zu angemessenen Preisen, ohne die für den Hauptauftrag
                    allenfalls gewährten Rabatte, Skonti oder sonstigen Nachlässe in Rechnung gestellt. Mangels
                    ausdrücklicher schriftlicher gegenteiliger Vereinbarung gelten unsere Preise nicht als
                    Pauschalpreise. Für erbrachte Regieleistungen ist das volle Entgelt auch ohne Unterfertigung von
                    Regiebestätigungen zu entrichten. Die Entgelte verstehen sich ohne Verpackungskosten. Die Verpackung
                    wird nicht zurückgenommen. Mangels anderer schriftlicher Vereinbarung verrechnen wir allfällige
                    Kosten für Zustellung, Einbau und Montage gesondert. Für Kostenvoranschläge ist uns vom Kunden
                    mangels konkreter Vereinbarung jedenfalls ein angemessenes Entgelt zu bezahlen. Wir sind berechtigt,
                    jederzeit angemessene Änderungen unserer Leistungs- bzw. Lieferpflichten vorzunehmen, sofern diese
                    sachlich gerechtfertigt sind. Insbesondere bei nicht in unserer Verfügungsmacht liegenden Änderungen
                    der Rohstoffpreise sowie der Lohn- und Betriebskosten sind wir berechtigt, entsprechende
                    Preisanpassungen hinsichtlich sämtlicher noch nicht gelieferter Waren vorzunehmen, ohne dass dies
                    den Kunden zum Rücktritt vom Vertrag berechtigt.
                </li>
                <li>
                    Mangels anderer schriftlicher Vereinbarung gilt unser Firmensitz als Leistungs- und Erfüllungsort.
                    Der Kunde hat für die ordnungsgemäß durchzuführende Anlieferung zumutbare Bedingungen
                    sicherzustellen. Bei verspäteter Anlieferung von vom Kunden beigestellten oder beizustellenden
                    Teilen oder Fahrzeugen verlängert sich die Lieferfrist entsprechend. Wird ein vereinbarter
                    Liefertermin um mehr als vier Wochen überschritten, ist der Kunde berechtigt, uns eine
                    Nachlieferungsfrist von weiteren vier Wochen zu setzen. Für den Fall des fruchtlosen Ablaufs dieser
                    Nachlieferfrist ist der Kunde zum Vertragsrücktritt berechtigt; dies jedoch nur, wenn er diesen
                    Rücktritt unter Setzung der Nachlieferfrist schriftlich angedroht hat. Sollte die Nachlieferfrist
                    jedoch ohne unser Verschulden nicht eingehalten worden sein, kann der Kunde (unter vorstehenden
                    Voraussetzungen) aber erst frühestens drei Monate nach dem ursprünglich vereinbarten Liefertermin
                    vom Vertrag zurücktreten. Schadensersatzansprüche des Kunden bloß wegen verspäteter Lieferung oder
                    Nichterfüllung sind ausgeschlossen, sofern uns diesbezüglich nicht Vorsatz oder grobe Fahrlässigkeit
                    nachgewiesen wird. Teillieferungen unsererseits sind zulässig und es sind bereits erbrachte
                    Teilleistungen vom Kunden auch abzunehmen.
                </li>
                <li>
                    Mangels anderer schriftlicher Vereinbarungen gelten folgende Zahlungsbedingungen: Unsere Rechnungen
                    sind sofort und in voller Höhe (abzugsfrei) fällig. Davon gänzlich oder auch nur teilweise
                    abweichende Zahlungsbedingungen (etwa andere Ziel- oder Skontovereinbarungen) gelten nur, wenn sie
                    von uns in individuellen Vereinbarungen, Bestätigungen oder Rechnungen gesondert schriftlich
                    zugesagt wurden, und gegebenenfalls nur für das jeweils betreffende Geschäft bzw. die betreffende
                    Rechnung. Selbst im letzteren Fall setzt die Inanspruchnahme von vereinbarten Skonti, Rabatten,
                    Nachlässen und dergleichen voraus, dass der Kunden sämtliche fällige Verbindlichkeiten uns gegenüber
                    (aus welchen Rechtsgeschäften oder Titeln immer) beglichen hat. Eine Inanspruchnahme von Skonti für
                    Teilzahlungen ist nicht möglich.Bei Überschreitung der Zahlungsfristen stehen uns Zinsen in der Höhe
                    der gesetzlichen Verzugszinsen nach § 352 UGB, mindestens aber in Höhe von 12 % zu. Weiters werden
                    in diesem Fall sofort Mahn- und Bearbeitungsspesen in der Höhe von mindestens € 40,00 fällig, und
                    zwar unbeschadet der Geltendmachung der tatsächlich durch den Zahlungsverzug des Kunden verursachten
                    Kosten und Aufwendungen. Bei Ratenzahlungsvereinbarung tritt bei Verzug auch mit nur einer Rate
                    Terminverlust ein. Es werden dann alle unsere offenen Forderungen zur Gänze fällig.
                </li>
                <li>
                    Der Kunde ist nicht berechtigt, Zahlungen wegen Gewährleistungsansprüchen oder sonstigen von uns
                    nicht anerkannten Gegenansprüchen zurückzuhalten oder gegen unsere Forderungen aufzurechnen. Soweit
                    dem Kunden im Fall einer Lieferung oder Leistung ein gesetzlich zwingendes Zurückbehaltungsrecht
                    bezüglich des Entgelts zustehen sollte, ist dieses jedenfalls auf die Höhe des Deckungskapitals der
                    Verbesserungskosten beschränkt. Forderungen gegen uns dürfen mangels schriftlicher Zustimmung
                    unsererseits nicht abgetreten.
                </li>
                <li>
                    Bestehen nach Annahme der Bestellung/des Auftrags begründete Zweifel an der Zahlungsfähigkeit oder
                    Kreditwürdigkeit des Kunden (insbesondere auch eine oder mehrere anhängige Exekutionen, negative
                    Auskünfte von Kreditschutzverbänden etc.) sind wir berechtigt, vor Leistungsbeginn nach unserer Wahl
                    entweder sofortige Barzahlung oder Sicherheitsleistung in voller Höhe zu verlangen oder vom Vertrag
                    zurückzutreten und vom Kunden Ersatz der bereits erfolgten Aufwendungen zu verlangen. In einem
                    solchen Fall werden auch sämtliche Ansprüche aus der Geschäftsverbindung mit dem Kunden für bereits
                    getätigte Lieferungen und Leistungen sofort fällig.
                </li>
                <li>
                    Bis zur vollständigen Zahlung des Kaufpreises bleiben gelieferte Waren unser Eigentum. Wird der
                    Liefergegenstand vor Beendigung des Eigentumsvorbehalts vom Kunden oder über dessen Auftrag von
                    einem Dritten bearbeitet oder mit einer anderen Sache vermischt oder verbunden, sind wir, soweit der
                    Eigentumsvorbehalt nicht ohnedies voll aufrechterhalten bleibt, jedenfalls Miteigentümer der neuen
                    oder verbundenen Sache mit Verhältnis unserer Forderung zum Wert der neuen oder verbundenen
                    Sache.Eine Weiterveräußerung der von uns gelieferten Waren oder des aus der Verarbeitung oder
                    Verbindung neu entstandenen Gegenstands sowie eine Vermietung oder Verpfändung vor vollständiger
                    Bezahlung unserer Forderung sind dem Kunden ohne unsere ausdrückliche Zustimmung nicht gestattet.
                    Sollte es zu einer solchen Weiterveräußerung kommen, so gilt die bezügliche Forderung des Kunden
                    gegen seinen Kunden als an uns abgetreten bzw. ist der Kunde verpflichtet, die diesbezügliche
                    Forderung an uns abzutreten und für die nötige Publizität bzw. Verständigung des Drittschuldners zu
                    sorgen. Unabhängig davon sind wir auch selbst berechtigt, für die Verständigung des Drittschuldners
                    zu sorgen. Der Kunde verpflichtet sich, uns sämtliche Bezug habende Daten sowie jede
                    Beeinträchtigung der Rechte an der in unserem Eigentum stehenden Sachen unverzüglich bekannt zu
                    geben.Kommt der Kunde mit seiner Zahlungspflicht uns gegenüber in Verzug oder verletzt er eine der
                    sich aus dem vereinbarten Eigentumsvorbehalt oder der Forderungsabtretung ergebenden Pflichten, wird
                    die gesamte aushaftende Forderung sofort fällig und der Kunde ist zum Ersatz eines allfälligen uns
                    dadurch entstandenen Schadens verpflichtet. In diesem Fall sind wir jedenfalls auch berechtigt, die
                    Herausgabe der in unserem Eigentum stehenden Sachen zu verlangen und diese beim Kunden oder bei
                    einem Dritten abzuholen, wobei der Kunde auf die Geltendmachung einer Zurückbehaltung, aus welchem
                    Grund auch immer, verzichtet. Der Kunde ist verpflichtet, die Kosten der Rücknahme der
                    Eigentumsvorbehaltsware, die keinen Vertragsrücktritt darstellt, zu tragen bzw. uns zu erstatten.
                </li>
                <li>
                    Sämtliche Ansprüche des Kunden aus Gewährleistung sowie sämtliche Schadensersatzansprüche, und zwar
                    ohne Rücksicht darauf, aus welchem Rechtsgrund sie hergeleitet werden, sind uns gegenüber zur Gänze
                    ausgeschlossen. Nur soweit dieser Haftungsausschluss auf Basis der jeweils geltenden gesetzlichen
                    Bestimmungen unwirksam sein sollte, gelten zwischen uns und dem Kunden hinsichtlich Gewährleistung
                    und Schadensersatz nachstehende Bestimmungen: Wir leisten Gewähr im Sinne des Gesetzes, jedoch nach
                    Maßgabe folgender Bestimmungen: Der Kunde ist bei sonstigem Ausschluss aller Gewährleistungs- und
                    Ersatzansprüche sowie des Rechts auf Irrtumsanfechtung verpflichtet, die gelieferte Ware oder die
                    bearbeiteten Materialien unverzüglich nach Übernahme zu überprüfen und sämtliche erkennbare Mängel
                    unverzüglich, spätestens aber innerhalb von sieben Werktagen ab Übergabe mittels eingeschriebenen
                    Briefs vollständig und in detaillierter Weise anzuzeigen. Ebenso müssen später hervorkommende Mängel
                    unverzüglich nach deren Entdeckung entsprechend angezeigt werden. Die Gewährleistungsfrist
                    betreffend unsere Leistungen beträgt sechs Monate ab Gefahrenübergang. Die Bestimmung des § 924 ABGB
                    über die Vermutung der Mangelhaftigkeit ist ausgeschlossen. Die Beweislast, dass ein von uns zu
                    vertretender Mangel bei Ablieferung vorgelegen hat, trifft ausschließlich den Kunden. Nach unserer
                    Wahl können Gewährleistungsansprüche in der Form erfüllt werden, dass der Mangel behoben oder durch
                    eine mangelfreie Ware ersetzt oder eine angemessene Preisminderung gewährt wird. Zur Mängelbehebung
                    sind uns jedenfalls zumindest drei Versuche einzuräumen, wobei ein Versuch erst als beendet gilt,
                    wenn unsererseits die Behebung des Mangels erklärt wurde oder länger als eine Woche keine Arbeiten
                    unsererseits mehr erfolgten. Eine Verlängerung der Gewährleistungsfrist wegen einer Mängelbehebung
                    erfolgt nicht. Mängel gelten unsererseits nur dann als anerkannt, wenn wir dies schriftlich und
                    ausdrücklich bestätigen. Der bloße Umstand allein, dass unsererseits Verbesserungsarbeiten
                    hinsichtlich vom Kunden behaupteter Mängel erfolgen, stellt kein Anerkenntnis einer Mangelhaftigkeit
                    dar.Wird die von uns gelieferte Ware oder das von uns bearbeitete Material vom Kunden verändert,
                    unsachgemäß behandelt oder verarbeitet, erlischt jede Gewährleistungs- oder Schadensersatzpflicht
                    unsererseits. Für Kosten einer durch den Kunden selbst vorgenommenen Mängelbehebung haben wir
                    ausschließlich dann aufzukommen, wenn wir hierzu unsere schriftliche Zustimmung gegeben haben. Der
                    Gewährleistungsanspruch des Kunden kann ohne unsere Zustimmung nicht auf Dritte übertragen
                    werden.Hinsichtlich der Eignung der gelieferten Ware und der bearbeiteten Materialien leisten wir
                    ausschließlich dahin gehend Gewähr, dass diese im Sinne unserer Bestimmungen und Vorschriften
                    verwendbar sind. Der Kunde hat dafür Sorge zu tragen, dass die vertragsgegenständlichen Waren
                    bestimmungsgemäß und gegebenenfalls ausschließlich im Sinne einer allfälligen mitgelieferten
                    Anleitung und insbesondere gemäß den einschlägigen, vom Kunden selbstständig zu besorgenden
                    Sicherheitsrichtlinien der Hersteller in ihrer jeweils geltenden Fassung gebraucht werden. Als
                    zugesicherte Eigenschaften gelten nur solche, die von uns schriftlich und ausdrücklich als solche
                    zugesichert wurden. Handelsübliche und/oder herstellungstechnisch bedingte Abweichungen in
                    Abmessung, Ausstattung, Material und dergleichen berechtigen nicht zu Beanstandung, Gewährleistung
                    oder Schadensersatz.Der Kunde trägt die volle Verantwortung dafür, dass das uns zur Bearbeitung
                    übergebene Material auch für die beauftragte Bearbeitung geeignet ist. Eine Haftung unsererseits bei
                    gänzlichem oder teilweisem Misslingen oder bei Eintritt eines Schadens, der seine Ursache im zur
                    Verfügung gestellten Material hat, ist ausgeschlossen. Soweit uns der Kunde nicht schriftlich über
                    die genaue Verwendung (Art, Einsatzort, Einsatzumfang und dergleichen) des zur Bearbeitung
                    übergebenen Materials oder der von uns gelieferten Ware vollständig schriftlich informiert, haften
                    wir jedenfalls nicht für Schäden oder sonstige Folgen, die ihre Ursache in der besonderen Verwendung
                    haben.Sofern uns nicht Vorsatz oder grobe Fahrlässigkeit zur Last fällt, sind sämtliche
                    Schadensersatzansprüche uns gegenüber zur Gänze ausgeschlossen, und zwar ohne Rücksicht darauf, aus
                    welchem Rechtsgrund sie hergeleitet werden, insbesondere auch für Personen-, Sach- und
                    Vermögensschäden, welche durch einen Fehler der Ware entstanden sind. Allfällige Regressforderungen,
                    die Kunden, Vertragspartner oder Dritte aus dem Titel der Produkthaftung im Sinne des
                    Produkthaftungsgesetzes gegen uns richten, sind ebenfalls ausgeschlossen, sofern uns nicht Vorsatz
                    oder grobe Fahrlässigkeit zur Last fällt. In jedem Fall umfassen Schadensersatzansprüche
                    ausschließlich die reinen Schadensbehebungskosten, nicht aber auch Folgeschäden, entgangenen Gewinn
                    oder dergleichen. Sämtliche Schadensersatzansprüche uns gegenüber verjähren binnen einem Jahr ab
                    Übernahme durch den Kunden.
                    <br/><br/>
                    Bei Arbeiten unsererseits an Türen, Türstöcken, Toren, Fenstern, Fenstergittern und Ähnlichem kann
                    es – insbesondere auch im Zusammenhang mit Gläsern und Glasflächen – auch bei sorgfältiger
                    Durchführung dieser Arbeiten zu geringfügigen Schäden, insbesondere zu Glasbruch, Kratzern,
                    Absplitterungen, Bohrlöchern, Dellen, Schrammen und Ähnlichem, kommen, wofür jegliche Haftung
                    unsererseits ausgeschlossen ist.
                    <br/><br/>
                    Für den Fall der Bearbeitung von Brandschutztüren gilt mangels ausdrücklicher und schriftlicher
                    gegenteiliger Erklärung des Kunden als vereinbart, dass deren weitere Benutzung als Brandschutztür
                    nicht erforderlich ist und diese Türen daher nach Bearbeitung unsererseits keine Brandschutz- und
                    Rauchabschlussfunktionalität gewährleisten müssen.
                </li>
                <li>
                    Allfällige von uns erstellte Entwürfe, Planungen, Zeichnungen, Muster und sonstige Unterlagen sind
                    unser alleiniges (allenfalls geistiges) Eigentum und dürfen vom Kunden nicht verwendet oder Dritten
                    zugänglich gemacht werden. Sollten uns vom Kunden (oder seitens von diesem beauftragter Dritter)
                    Entwürfe, Planungen, Zeichnungen, Muster und sonstige Unterlagen zur Verfügung gestellt und vom
                    Kunden nicht binnen sechs Wochen nach Auftragsablehnung oder -durchführung abgeholt werden, sind wir
                    nach erfolgloser Nachfristsetzung von 14 Tagen zu deren Vernichtung berechtigt.
                </li>
                <li>
                    Sofern nicht schriftlich anderes vereinbart wird, haben Mitteilungen und Erklärungen uns gegenüber
                    grundsätzlich an unsere im Firmenbuch eingetragene Geschäftsanschrift oder an die Geschäftsanschrift
                    unseres Sitzes zu erfolgen. Sofern im Rahmen einer Vereinbarung mit uns oder aufgrund der
                    gegenständlichen allgemeinen Geschäftsbedingungen Schriftlichkeit verlangt wird, ist darunter
                    grundsätzlich die einfache Schriftform im Sinne des § 886 ABGB zu verstehen, wobei auch ein
                    unterfertigtes und durch ein erfolgreiches Sendeprotokoll bestätigtes Telefax und auch eine E-Mail
                    (auch ohne elektronische Signatur) dieses Schriftlichkeitserfordernis erfüllen.
                </li>
                <li>
                    Sollten eine oder mehrere Bestimmungen der vorstehenden Bedingungen rechtsunwirksam sein, wird die
                    Wirksamkeit der anderen Bestimmungen und der Bestimmungen der individuellen Vereinbarung mit dem
                    Kunden hiervon nicht berührt. Die Vertragsparteien verpflichten sich, allfällige unwirksame
                    Bestimmungen durch Neuregelungen, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung
                    möglichst nahekommen, aber zulässig sind, zu ersetzen.
                </li>
                <li>
                    Firmenbuchgericht für sämtliche Streitigkeiten ist für beide Teile ausschließlich das zuständige
                    Gericht in 6130 Schwaz. Auf das Vertragsverhältnis findet ausschließlich österreichisches Recht
                    Anwendung, mit Ausnahme von UN-Kaufrecht.
                </li>
                <li>
                    Alternative Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie unter http://ec.europa.eu/consumers/odr/ finden. Wir sind bereit, an einem außergerichtlichen Schlichtungsverfahren teilzunehmen.
                </li>
            </ol>
        </div>
    );

};