import {useHistory, useParams} from "react-router-dom";
import {del, get, put} from "../../js/api";
import {markDirty, showMessage} from "../../js/main";
import {Fragment, useEffect, useState} from "react";
import {DataTable} from "../../component/datatable/DataTable";
import {UserInput} from "../../component/UserInput";
import {BreadCrumb} from "../../component/Breadcrumb";
import {InputCheckbox} from "../../component/form/InputCheckbox";
import {Button} from "../../component/form/Button";
import {InputFile} from "../../component/form/InputFile";
import {createFileHandler} from "./Edit";
import {uploadFileForUser} from "../../controller/Profile";


export const UpdateUser = ({users}) => {

    const {selectedUsername} = useParams();

    const history = useHistory();

    const [submitting, setSubmitting] = useState(false);

    const [selectedInfo, setSelectedInfo] = useState();
    const [file, setFile] = useState();
    const [imgSrc, setImgSrc] = useState();
    const [locksystems, setLocksystems] = useState(null);
    const [userInitialLocksystems, setUserInitialLocksystems] = useState([]);
    const [userLocksystems, setUserLocksystems] = useState([]);

    useEffect(() => {
            get("/locksystems?username=" + encodeURIComponent(selectedUsername)).then(permittedSystems => {
                setUserLocksystems(permittedSystems);
                setUserInitialLocksystems(permittedSystems);

                get("/locksystems").then(allSystems => {
                    const sortedArray = [
                        ...allSystems.filter(locksystem => permittedSystems.find(permitted => permitted.id === locksystem.id)),
                        ...allSystems.filter(locksystem => !permittedSystems.find(permitted => permitted.id === locksystem.id)),
                    ];
                    setLocksystems(sortedArray);
                });
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (users) {
            const user = users.find(e => e.username === selectedUsername);
            setSelectedInfo(users.find(e => e.username === selectedUsername));
            setImgSrc(user.fileUri);
        }
    }, [users]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateUser = () => {
        const added = userLocksystems.filter(locksystem => !userInitialLocksystems.find(i => i.id === locksystem.id));
        const removed = userInitialLocksystems.filter(locksystem => !userLocksystems.find(i => i.id === locksystem.id));

        let expectedRequests = added.length + removed.length + 1;
        let successfullReq = 0;
        const successWrapper = (respPromise) => {
            respPromise.then(() => {
                successfullReq++;
                if (successfullReq === expectedRequests) {
                    markDirty();
                    showMessage("Gespeichert");
                }
            }).finally(() => {
                setSubmitting(false);
            });
        }
        successWrapper(put("/customers/" + encodeURIComponent(selectedInfo.username), selectedInfo));

        if(file){
            expectedRequests++;
            successWrapper(uploadFileForUser(selectedInfo.username, file));
        }

        added.forEach(a => {
            successWrapper(put("/customers/" + encodeURIComponent(selectedInfo.username) + "/addLocksystem/" + a.id));
        })

        removed.forEach(r => {
            successWrapper(del("/customers/" + encodeURIComponent(selectedInfo.username) + "/addLocksystem/" + r.id));
        })
    };

    const handleLocksystemCheckbox = (newValue, name) => {
        const id = parseInt(name);
        if (newValue) {
            setUserLocksystems([...userLocksystems, locksystems.find(locksystem => locksystem.id === id)]);
        } else {
            setUserLocksystems(userLocksystems.filter(lockstystem => lockstystem.id !== id));
        }
    };

    const columns = [
        {
            name: "Berechtigt",
            cell: (row) => <InputCheckbox name={row.id}
                                          value={!!userLocksystems.find(locksystem => locksystem.id === row.id)}
                                          setter={handleLocksystemCheckbox}/>,
            sortable: false,
            maxWidth: '160px',
        },
        {
            name: "Anlagennummer",
            selector: (row) => row.assetNumber,
            sortable: true,
        },
        {
            name: "System",
            selector: (row) => row.sys,
            sortable: true,
        },
        {
            name: "Notiz",
            selector: (row) => row.note,
            sortable: true,
        },
        {
            name: "Rabatt %",
            selector: (row) => row.discount,
            sortable: true,
            right: true,
            maxWidth: '160px',
        }
    ];

    return (
        <Fragment>
            <BreadCrumb currentTitle="Benutzer bearbeiten" parentTitle="Benutzer" parentLink="/benutzer"/>
            <form onSubmit={e => {
                e.preventDefault();
                setSubmitting(true);
                updateUser();
            }}>

                {selectedInfo && <Fragment>
                    <div className="default">
                        <InputFile label="Bild" name="image" accept="image/*" setter={createFileHandler(setImgSrc, setFile)} maxMBSize={10}/>
                        {imgSrc && <img id="preview" className="preview-img" src={imgSrc}/>}
                    </div>

                    <UserInput initialUserInfo={selectedInfo} setUserFn={setSelectedInfo}/>
                </Fragment>
                }

                <hr/>
                <h2 className="pt-1">Berechtigte Schließanlagen</h2>

                <DataTable data={locksystems}
                           columns={columns} />

                <div className={"safe-actions mt-1"}>
                    <Button key="back" theme="secondary-button mr-1 mb-1" width={230} submitting={submitting} onClick={e => {e.preventDefault(); window.history.back()}}>zurück</Button>
                    <Button key="submit" theme="primary-button mb-1" width={230} submitting={submitting}>speichern</Button>
                </div>
            </form>
        </Fragment>
    );

}