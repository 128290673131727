import {CreateNewAction} from "../../component/CreateNewAction";
import {useHistory} from "react-router-dom";
import {DataTable} from "../../component/datatable/DataTable";
import {BreadCrumb} from "../../component/Breadcrumb";


export const Overview = ({locksystems, keys}) => {

    const columns = [
        {
            name: "Anlagennummer",
            selector: (row) => row.assetNumber,
            sortable: true,
            maxWidth: '200px',
        },
        {
            name: "System",
            selector: (row) => row.sys,
            sortable: true,
        },
        {
            name: "Schlüssel",
            selector: (row) => keys?.get(row.keyId)?.title ?? "-",
            sortable: true,
        },
        // {
        //     name: "Firma",
        //     selector: (row) => "TODO", // beachten auch dass es 2 oder mehr sein können
        //     sortable: true,
        // },
        {
            name: "Notiz",
            selector: (row) => row.note,
            sortable: true,
            maxWidth: '200px',
        },
        {
            name: "Rabatt %",
            selector: (row) => row.discount,
            sortable: true,
            right: true,
            sortFunction: (first, second) => first.discount - second.discount,
            maxWidth: '160px',
        }
    ];

    const history = useHistory();

    const handleRowClick = (row) => {
        history.push("/schliessanlagen/edit/" + row.id);
    };

    return (
        <div>
            <BreadCrumb currentTitle="Schließanlagen"/>

            <div className="data-table">
                <DataTable data={locksystems}
                           columns={columns}
                           actions={<CreateNewAction buttonText="Neue Schließanlage erstellen" redirect="/schliessanlagen/neu"/>}
                           selectableRows
                           nameField="assetNumber"
                           deleteApi="/locksystems/"
                           url="/schliessanlagen"
                           preventDeletingAll
                           onRowClicked={handleRowClick}/>
            </div>
        </div>
    );

};