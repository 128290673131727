import React, {useEffect} from 'react';
import {Link} from "react-router-dom";
import {ReactComponent as BackButton} from "../img/icon/back-arrow.svg";
import {setSiteTitle} from "../js/main";

export const BreadCrumb = ({currentTitle, parentTitle, parentLink}) => {

    useEffect(() => {
        setSiteTitle(currentTitle);
    }, [currentTitle]);

    return (
        <h1>
            {parentTitle &&
             <span className="breadcrumb">
                <Link to={parentLink} className="def">
                    <BackButton className="def"/> {parentTitle}
                </Link> /&nbsp;
            </span>}
            {currentTitle}
        </h1>
    );

};