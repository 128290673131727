import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {Route, Switch} from "react-router";
import {Layout} from "./Layout";
import {NavAdmin} from "./NavAdmin";
import {Orders} from "../../controller/Orders";
import {HomeAdmin} from "../../view/home/HomeAdmin";
import {User} from "../../controller/User";
import {Locksystem} from "../../controller/Locksystem";
import {Keys} from "../../controller/Keys";
import {Shipping} from "../../controller/Shipping";
import {NotFound} from "../../view/error/NotFound";
import {Profile} from "../../controller/Profile";
import {MyOrders} from "../../controller/MyOrders";
import {CreateOrder} from "../../controller/CreateOrder";
import {HomeUser} from "../../view/home/HomeUser";
import {NavUser} from "./NavUser";

import {ConditionalRoute, RouteType, RoutingNotifier} from "../func/ConditionalRoute";
import {globalKeys, putGlobal} from "../../js/main";
import {Impressum} from "../../view/home/Impressum";
import {MyLocksystems} from "../../controller/MyLocksystems";


export const KeyDirect = ({usr}) => {

    const [isAdmin, setIsAdmin] = useState(null);
    const [username, setUsername] = useState(usr);
    const [routeNotify] = useState(new RoutingNotifier());

    useEffect(() => {
        Auth.currentSession()
            .then((session) => {
                const isAdmin = (session.getIdToken().payload["cognito:groups"] || []).includes("admins");
                putGlobal(globalKeys.isAdmin, isAdmin)
                setIsAdmin(isAdmin)
                routeNotify.notifyRouting(isAdmin ? [RouteType.ADMIN] : [RouteType.USER]);

                setUsername(session.getIdToken().payload["cognito:username"] || null);
            })
            .catch((e) => {
                console.error(e);
                setIsAdmin(null)
                setUsername(null);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (
        <Layout username={username} mainNav={isAdmin ? <NavAdmin/> : <NavUser/>}>
            <Switch>
                <Route exact path="/" component={isAdmin ? HomeAdmin : HomeUser}/>

                <ConditionalRoute routingNotifier={routeNotify} type={RouteType.ADMIN} path="/benutzer" component={User}/>
                <ConditionalRoute routingNotifier={routeNotify} type={RouteType.ADMIN} path="/schliessanlagen" component={Locksystem}/>
                <ConditionalRoute routingNotifier={routeNotify} type={RouteType.ADMIN} path="/schluessel" component={Keys}/>
                <ConditionalRoute routingNotifier={routeNotify} type={RouteType.ADMIN} path="/versand" component={Shipping}/>
                <ConditionalRoute routingNotifier={routeNotify} type={RouteType.ADMIN} path="/bestellungen" component={Orders}/>

                <ConditionalRoute routingNotifier={routeNotify} type={RouteType.USER} exact path="/neue-bestellung" component={CreateOrder}/>
                <ConditionalRoute routingNotifier={routeNotify} type={RouteType.USER} path="/meine-bestellungen" component={MyOrders}/>
                <ConditionalRoute routingNotifier={routeNotify} type={RouteType.USER} path={"/meine-schliessanlagen"} component={MyLocksystems} />

                <Route path="/profil" component={Profile}/>
                <Route path="*" component={NotFound}/>
            </Switch>
        </Layout>
    );

};
