import {Layout} from "../component/layout/Layout";
import {Route, Switch, useParams} from "react-router-dom";
import {Overview} from "../view/myOrders/Overview";
import {UpdateOrder} from "../view/myOrders/UpdateOrder";
import {useEffect, useState} from "react";
import {get} from "../js/api";
import {CreateNewQrOrder} from "../view/qrOrder/CreateNewQrOrder";
import {UpdateQrOrder} from "../view/qrOrder/UpdateQrOrder";
import {useHistory} from "react-router";
import {OrderInformation} from "../component/OrderInformation";


export const QrOrder = () => {

    const {qr} = useParams();
    const [orderTemplateInfo, setOrderTemplateInfo] = useState();
    const [customerOrder, setCustomerOrder] = useState();
    const [displayError, setDisplayError] = useState(false);
    const [paymentLink, setPaymentLink] = useState();

    const history = useHistory();

    useEffect(() => {
        get("/public/qrOrders/"+qr).then(setOrderTemplateInfo).catch((e) => {
            setDisplayError(true);
        });
    }, [])

    useEffect(() => {
        if(orderTemplateInfo) {
            setCustomerOrder(orderTemplateInfo.customerOrderInfo)
        }
    }, [orderTemplateInfo])


    const signed = (directPaymentOrderInfo) =>{
        const url = new URL(directPaymentOrderInfo.paymentLink);
        history.push(url.pathname);
    }

    return (<>
    <Layout>
        {orderTemplateInfo && <>
            {!customerOrder && <CreateNewQrOrder templateOrder={orderTemplateInfo.templateOrderInfo} onCreate={setCustomerOrder}/>}
            {customerOrder && customerOrder.state == "CREATED" && <UpdateQrOrder orderInfo={customerOrder} signed={signed}/>}
            {customerOrder && customerOrder.state != "CREATED" && <>
                <OrderInformation order={customerOrder} showUser={false}/>
            </>}
        </>}
        {displayError && <h1>Der QR-Code ist leider nicht gültig</h1>}
    </Layout>
    </>)
}