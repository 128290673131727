import {API, Auth} from "aws-amplify";
import {showError} from "./main";


const createInit = async (body) => {
    let session = null;

    try{
        session = await Auth.currentSession();
    }catch (e){

    }

    let myInit = {
        headers: {
        },
    };

    if(session){
        myInit.headers["Authorization"] = `Bearer ${session.getIdToken().getJwtToken()}`;
    }

    if (body != null) {
        myInit["body"] = body;
    }

    return myInit;
}

export const handleApiError = (error) => {
    showError("Fehler bei Serverkommunikation: " + error);
}

export const get = async (path, api = "key-direct-api") => {
    const init = await createInit(null);
    try {
        return await API.get(api, path, init); //.catch wouldn't work, because afterwards .then would be called, because chain would be marked as resolved.
    } catch (error) {
        handleApiError(error);  // rethrow, because when async method ends without exception .then is called, but we don't want that
        throw error;
    }
}

export const del = async (path, handleError = true) => {
    const init = await createInit(null);
    try {
        return await API.del("key-direct-api", path, init);
    } catch (error) {
        if (handleError){
            handleApiError(error);
        }
        throw error;
    }
}

export const post = async (path, body, handleError = true, apiName="key-direct-api") => {
    const init = await createInit(body);
    try {
        return await API.post(apiName, path, init);
    } catch (error) {
        if (handleError){
            handleApiError(error);
        }
        throw error;
    }
}

export const patch = async (path, body, handleError = true, apiName="key-direct-api") => {
    const init = await createInit(body);
    try {
        return await API.patch(apiName, path, init);
    } catch (error) {
        if (handleError){
            handleApiError(error);
        }
        throw error;
    }
}

export const put = async (path, body, apiName = "key-direct-api") => {
    const init = await createInit(body);
    try {
        return await API.put(apiName, path, init);
    } catch (error) {
        handleApiError(error);
        throw error;
    }
}