import {getOrderStateTag} from "../myOrders/Overview";
import {germanDateTime} from "../../js/util";

export const RequestInformation = ({order}) =>{

    return (<>
        <h2>Anfrageinformationen - Fotoupload Schlüssel</h2>
        <table className="mb-1">
            <tbody>
            <tr>
                <td width="200"><strong>Status</strong></td>
                <td><strong>{getOrderStateTag(order.state)}</strong></td>
            </tr>
            <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>Anfragenummer</td>
                <td>{order.id}</td>
            </tr>
            <tr>
                <td>Datum</td>
                <td>{germanDateTime(order.creationDate)}</td>
            </tr>
            <tr>
                <td>Beschreibung</td>
                <td>{order.summary}</td>
            </tr>
            <tr>
                <td>E-Mail</td>
                <td>{order.email}</td>
            </tr>
            </tbody>
        </table>
    </>);
}