import {CreateNewAction} from "../../component/CreateNewAction";
import {useHistory} from "react-router-dom";
import {DataTable} from "../../component/datatable/DataTable";
import {BreadCrumb} from "../../component/Breadcrumb";


export const Overview = ({locksystems, keys}) => {

    const columns = [
        {
            name: "Anlagennummer",
            selector: (row) => row.assetNumber,
            sortable: true,
            maxWidth: '200px',
        },
        {
            name: "System",
            selector: (row) => row.sys,
            sortable: true,
        },
        {
            name: "Schlüssel",
            selector: (row) => keys?.get(row.keyId)?.title ?? "-",
            sortable: true,
        },
        // {
        //     name: "Firma",
        //     selector: (row) => "TODO", // beachten auch dass es 2 oder mehr sein können
        //     sortable: true,
        // },
    ];

    const history = useHistory();

    const handleRowClick = (row) => {
        history.push("/meine-schliessanlagen/edit/" + row.id);
    };

    return (
        <div>
            <BreadCrumb currentTitle="Meine Schließanlagen"/>

            <div className="data-table">
                <DataTable data={locksystems}
                           columns={columns}
                           nameField="assetNumber"
                           url="/meine-schliessanlagen"
                           preventDeletingAll
                           onRowClicked={handleRowClick}/>
            </div>
        </div>
    );

};