import {useEffect, useState} from "react";
import Bus from "../js/Bus";


export const Message = () => {

    let [visibility, setVisibility] = useState(false);
    let [messages, setMessages] = useState([]);
    let [activeMsg, setActiveMsg] = useState();
    //timeout stuff
    let [timeoutHandle, setTimeoutHandle] = useState(0);
    let [timeoutSet, setTimeoutSet] = useState();

    //when messages change, also react.state hooks change, so we have to create new listeners so closures are up to date
    const register = () => {
        Bus.removeAllListeners("message") //only one message allowed
        let msgs = [];
        Bus.addListener("message", ({message, type}) => {
            msgs = [...messages, ...msgs, {message:message, type:type}]; //append
            setMessages(msgs);
        });
    }
    useEffect(() => {
        register();
    }, []);

    useEffect(() => {
        if(messages.length > 0){
            //fifo
            setActiveMsg(messages[0])

            clearTimeout(timeoutHandle);
            setTimeoutHandle(setTimeout(() => {
                clearActiveMsg();
            }, 5000));

            setVisibility(true);
        }else{
            setVisibility(false);
        }
        register();
    }, [messages]);

    const clearActiveMsg = () => {
        const msgs = [...messages];
        msgs.shift();
        setMessages(msgs);
    }

    return (
        (visibility && activeMsg) ?
        <div className={`alert alert-${activeMsg.type}`}>
            <span>{activeMsg.message}</span>
            <span onClick={() => clearActiveMsg()}
                  className="close"
                  aria-label="schließen">x</span>
        </div> : null
    );

};