import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {get, post} from "../../js/api";
import {OrderInformation} from "../../component/OrderInformation";
import {OrderItems} from "../../component/OrderItems";
import {OrderShippingAndTotal} from "../../component/OrderShippingAndTotal";
import {OrderAddressDisplay} from "../myOrders/OrderAddressDisplay";
import {SelectReact} from "../../component/form/SelectReact";
import {paymentTypeDirect} from "../../js/constants";
import {Button} from "../../component/form/Button";
import {OrderTotal} from "../../component/OrderTotal";
import {InputText} from "../../component/form/InputText";

export const CreateNewQrOrder = ({templateOrder, onCreate}) =>{

    const {qr} = useParams();
    const [availableShipping, setAvailableShipping] = useState([]);
    const [shippingSelectOptions, setShippingSelectOptions] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [email, setEmail] = useState();

    const [selectedShipping, setSelectedShipping] = useState();

    const [selectedPaymentType] = useState(paymentTypeDirect.value);

    useEffect(() => {
            get("/public/shippings").then(shippings => {
                setAvailableShipping(shippings);
                const options = [];
                shippings.forEach(s => options.push({value: s.id, label: s.title + " (" + s.price + " €)"}));
                setShippingSelectOptions(options);
            });
        }, [])

    const order = () =>{
        setSubmitting(true);

        var createQrOrderReq = {
            qr:qr,
            email:email,
            shippingInfo:availableShipping.find(a => a.id == selectedShipping),
            paymentType:selectedPaymentType
        }

        post("/public/qrOrders", createQrOrderReq).then(onCreate).finally(()=>setSubmitting(false));
    }

    return (<>
        <h1>Neue Bestellung aufgeben</h1>
        <OrderItems data={templateOrder}/>

        {templateOrder.billingAddress && <>
            <h2 className="mt-1d5 mb-03">{templateOrder.billingAddress.type === "BOTH" ? "Rechnungs- und Lieferadresse" : "Rechnungsadresse"}</h2>
            <OrderAddressDisplay address={templateOrder.billingAddress}/>

            {templateOrder.billingAddress.type !== "BOTH" &&
                <>
                    <h2 className="mt-1d5 mb-03">Lieferadresse</h2>
                    <OrderAddressDisplay address={templateOrder.shippingAddress}/>
                </>}
        </>}
        <form onSubmit={(e) => {
            e.preventDefault();
            order();
        }}>
            <h2 className="mb-05">Versand auswählen</h2>
            <div className="default">
                <SelectReact name="versandoption" label="Versandoption" value={selectedShipping}
                             required options={shippingSelectOptions}
                             setter={(newValue) => setSelectedShipping(newValue)}/>
            </div>
            <h2 className="mt-2 mb-05">Bezahlungsart auswählen</h2>
            <div className="default mb-1">
                <SelectReact name="paymentType" label="Bezahlungsart"
                             value={selectedPaymentType}
                             required options={[paymentTypeDirect]}
                             disabled={true}/>
            </div>
            <div className={"default"}>
                <InputText name={"email"} label={"E-Mail Adresse"} required={true} value={email} setter={setEmail}/>
            </div>
            <div className="right">
                <OrderTotal orderItems={templateOrder.items}
                            shipping={availableShipping.find(s => s.id === selectedShipping)}/>
            </div>
            <div className={"safe-actions mt-1"}>
                <Button key="back" theme="secondary-button mr-1 mb-1" width={230}
                        submitting={submitting} onClick={e => {
                    e.preventDefault();
                    window.history.back()
                }}>zurück</Button>
                <Button key="submit" theme="primary-button mb-1" width={230}
                        submitting={submitting}>Bestellung abschicken</Button>
            </div>
            <br/><br/>
        </form>
    </>)
}