import {get} from "../../js/api";
import {useEffect, useMemo, useState} from "react";
import {SelectOne} from "../../component/form/SelectOne";
import {InputText} from "../../component/form/InputText";
import {InputTextarea} from "../../component/form/InputTextarea";
import {InputPrice} from "../../component/form/InputPrice";
import {InputType} from "../../component/form/InputType";
import {BreadCrumb} from "../../component/Breadcrumb";
import {applyDiscount} from "../../js/util";
import {Button} from "../../component/form/Button";
import {InputFile} from "../../component/form/InputFile";
import {ReactComponent as IconFile} from "../../img/icon/download.svg";
import {ReactComponent as IconFile2} from "../../img/icon/externallink.svg";
import {LocksystemKeyEdit} from "../myLocksystems/LocksystemKeyEdit";
import {LocksystemEvents} from "./LocksystemEvents";
import {SelectReact} from "../../component/form/SelectReact";
import {InputCheckbox} from "../../component/form/InputCheckbox";

export const Edit = ({initialSystemInfo, saveAction, submitting, setSubmitting}) => {

    const priceModeType = {
        discount: "discount",
        discountPrice: "discountPrice"
    }

    const [availableKeys, setAvailableKeys] = useState([]);
    const [locksystemInfo, setLocksystemInfo] = useState(initialSystemInfo);
    const [locksystemKeysInfo, setLocksystemKeysInfo] = useState([]);
    const [price, setPrice] = useState("0,00");
    const [keyOptions, setKeyOptions] = useState([]);
    const [priceMode, setPriceMode] = useState(priceModeType.discount);
    const [printIssueDocument, setPrintIssueDocument] = useState(false);

    //changes
    const [file, setFile] = useState();
    const [locksystemKeysPatch, setLocksystemKeysPatch] = useState();

    useEffect(() => {
        get("/keys").then((resp) => {
            setAvailableKeys(resp);
            const keyOptions = [];
            resp.forEach(e => {
                keyOptions.push({value:e.id, label: e.title});
            });
            setKeyOptions(keyOptions);
        });
    }, []);

    useEffect(() => {
        if(locksystemInfo.id) {
            get("/locksystems/"+locksystemInfo.id+"/keys").then((resp) => {
                setLocksystemKeysInfo(resp);
            });
        }
    }, [locksystemInfo?.id]);

    const handleUpdate = (newValue, field) => {
        let updatedInfo = Object.fromEntries(Object.entries(locksystemInfo));
        updatedInfo[field] = newValue;
        setLocksystemInfo(updatedInfo);
    };

    const handleDiscountUpdate = (newValue) => {
        let updated = Object.fromEntries(Object.entries(locksystemInfo));
        updated.discountPrice = null;
        updated.discount = newValue;
        setLocksystemInfo(updated);
    }
    const handlePriceUpdate = (newValue) => {
        let updated = Object.fromEntries(Object.entries(locksystemInfo));
        updated.discountPrice = newValue;
        updated.discount = 0;
        setLocksystemInfo(updated);
    }

    const handleFile = (file) => {
        setFile(file);
    }

    useEffect(() => {
        const key = availableKeys.find(key => String(key.id) === String(locksystemInfo.keyId));
        if (key) {
            if(locksystemInfo.discountPrice != null){
                setPriceMode(priceModeType.discountPrice);
                setPrice(locksystemInfo.discountPrice);
            }else{
                setPriceMode(priceModeType.discount);
                setPrice(applyDiscount(key.price, locksystemInfo.discount));
            }
        }
    }, [availableKeys, locksystemInfo.keyId, locksystemInfo.discount, locksystemInfo.discountPrice]);



    return (
        <div>
            <BreadCrumb currentTitle={'Schließanlage ' + (locksystemInfo.id === null ? 'hinzufügen' : 'bearbeiten')}
                        parentTitle="Schließanlagen"
                        parentLink="/schliessanlagen"/>

            <form onSubmit={e => {
                e.preventDefault();
                setSubmitting(true);
                const locksystemKeysPatchRequest = {
                    createIssueDocument: printIssueDocument,
                    updated: locksystemKeysPatch
                }
                saveAction(locksystemInfo, file, locksystemKeysPatchRequest);
            }}>
                <div className="default">
                    <InputText name="assetNumber"
                               value={locksystemInfo.assetNumber}
                               label="Anlagennummer"
                               setter={handleUpdate}
                               required/>
                    <InputText name="sys" value={locksystemInfo.sys} label="System" setter={handleUpdate} required/>
                    <InputType name="discount"
                               type="number"
                               min="0"
                               max="100"
                               step="0.1"
                               value={locksystemInfo.discount}
                               label="Rabatt %"
                               setter={handleDiscountUpdate}
                               required/>
                    <SelectReact name="keyId" label="Schlüssel" value={locksystemInfo.keyId} required
                                 options={keyOptions} setter={handleUpdate}/>
                    <InputTextarea name="note" value={locksystemInfo.note} label="Notiz" setter={handleUpdate}/>
                    <InputPrice name="calculated" value={price} label="Preis € (netto)" setter={handlePriceUpdate}/>
                    <InputFile name="fileUpload" maxMBSize="10" accept="*" label="Schließplan" setter={handleFile}/>
                    {locksystemInfo.fileUri && <a className="file-uri" href={locksystemInfo.fileUri} target="_blank"><IconFile className="file-svg file-svg-center mr-1"/><h3 className={"file-svg-center"}>Schließplan herunterladen</h3></a>}
                </div>
                <div className="default">
                    <InputText name="externalUri" value={locksystemInfo.externalUri} label={"Externer Dokumentlink"} setter={handleUpdate}/>
                    {locksystemInfo.externalUri && <a className="file-uri" href={locksystemInfo.externalUri} target="_blank"><IconFile2 className="file-svg file-svg-center mr-1"/><h3 className={"file-svg-center"}>Schließplanlink öffnen</h3></a>}
                </div>

                <hr/>
                <LocksystemKeyEdit locksystem={locksystemInfo} initialLocksystemKeys={locksystemKeysInfo} canCreate={true} setPatch={setLocksystemKeysPatch}/>

                <hr style={{'marginBottom': '1.5em'}}/>
                <div className={"safe-actions"}>
                    {locksystemKeysPatch && locksystemKeysPatch.length > 0 && <div className="default"><InputCheckbox label={"Schlüsseldokumente drucken?"} value={printIssueDocument} setter={setPrintIssueDocument} name={"printIssueDocument"}></InputCheckbox></div>}
                    <Button key="back" theme="secondary-button mr-1 mb-1" width={230} submitting={submitting} onClick={e => {e.preventDefault(); window.history.back()}}>zurück</Button>
                    <Button key="submit" theme="primary-button mb-1" width={230} submitting={submitting}>speichern</Button>
                </div>
                <hr/>
                <LocksystemEvents locksystemId={locksystemInfo.id}/>

            </form>
        </div>
    );
};