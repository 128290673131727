export const InputText = ({name, value, label, setter, required = false, disabled = false, concatId = null, ...props}) => {

    const identifier = concatId ? name + concatId : name;

    return (
        <div className="form-input">
            {label &&
            <label htmlFor={identifier}>
                {label} {required && <span className="primary-link bold">*</span>}
            </label>}
            <input id={identifier}
                   type="text"
                   value={value === 0 ? value : (value || '')}
                   onChange={e => setter(e.target.value, name)}
                   disabled={disabled}
                   required={required}
                   {...props}/>
        </div>
    );

};