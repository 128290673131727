import {useState} from "react";
import {InputText} from "../../component/form/InputText";
import {InputPrice} from "../../component/form/InputPrice";
import {BreadCrumb} from "../../component/Breadcrumb";
import {Button} from "../../component/form/Button";
import {InputCheckbox} from "../../component/form/InputCheckbox";


export const Edit = ({initialShippingInfo, saveAction, submitting}) => {

    const [shippingInfo, setShippingInfo] = useState(initialShippingInfo);

    const handleChange = (newValue, field) => {
        const updated = Object.fromEntries(Object.entries(shippingInfo));
        updated[field] = newValue;
        setShippingInfo(updated);
    };

    return (
        <div>
            <BreadCrumb currentTitle={"Versandsoption " + (shippingInfo.id === null ? "hinzufügen" : "bearbeiten")}
                        parentTitle="Versand"
                        parentLink="/versand"/>

            <form onSubmit={e => {
                e.preventDefault();
                saveAction(shippingInfo);
            }}>
                <div className="default mb-2">
                    <InputText name="title" value={shippingInfo.title} label="Name" setter={handleChange} required/>
                    <InputPrice name="price" value={shippingInfo.price} label="Preis € (netto)" setter={handleChange} required/>
                    <InputCheckbox name="pickupAtStore" value={shippingInfo.pickupAtStore} label="Abholoption" setter={handleChange} big/>
                </div>
                <div className={"safe-actions"}>
                    <Button key="back" theme="secondary-button mr-1 mb-1" width={230} submitting={submitting} onClick={e => {e.preventDefault(); window.history.back()}}>zurück</Button>
                    <Button key="submit" theme="primary-button mb-1" width={230} submitting={submitting}>speichern</Button>
                </div>
            </form>
        </div>
    );

};