import {Edit} from "../view/user/Edit";
import {get, post, put} from "../js/api";
import {empty, path} from "./User";
import {Auth} from "aws-amplify";
import {useEffect, useState} from "react";
import {showMessage} from "../js/main";
import {corsFetch} from "../js/util";

export const getOwnUser = async () => {
    const session = await Auth.currentSession();
    return get(path + "/" + encodeURIComponent(session.idToken.payload["cognito:username"]));
};


export const uploadFileForUser = async (username, file) => {
    if(file){
        const resp = await post(path+"/"+encodeURIComponent(username) +"/generateFileUpload", {"fileType":file.type});
        await corsFetch(resp.uploadUrl, {
            method: "PUT",
            body: file
        });
    }
}

export const Profile = () => {

    const [ownUser, setOwnUser] = useState(empty);

    const [submitting, setSubmitting] = useState(false);

    const updateUser = (userInfo, file) => {
        put(path + "/" + encodeURIComponent(userInfo.username), userInfo).then(async () => {
            await uploadFileForUser(userInfo.username, file);
        }).then(() => {
            showMessage("Gespeichert");
        }).finally(() => {
            setSubmitting(false);
        });
    }

    useEffect(() => {
        getOwnUser().then((user) => setOwnUser(user));
    }, [])

    return (
        <Edit initialUserInfo={ownUser} saveAction={updateUser} showRootLink={false} submitting={submitting} setSubmitting={setSubmitting}/>
    );

}