import {Edit} from "./Edit";
import {get, post, put} from "../../js/api";
import {markDirty, showMessage} from "../../js/main";
import {useHistory, useParams} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {patchLocksystemKeys, uploadFile} from "../myLocksystems/UpdateLocksystem";

export const UpdateLocksystem = ({locksystems, submitting, setSubmitting}) => {

    const history = useHistory();
    const {locksystemId} = useParams();

    const [selectedSystem, setSelectedSystem] = useState();

    useEffect(() => {
        if(locksystems){
            setSelectedSystem(locksystems.find(e => e.id === parseInt(locksystemId)));
        }
    }, [locksystems]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSave = (locksystem, file, locksystemKeysPatch) => {
        put("/locksystems/" + locksystem.id, locksystem).then(async() => {
            await uploadFile(locksystem.id, file);
        }).then(async () => {
            await patchLocksystemKeys(locksystem.id, locksystemKeysPatch);
        }).then(() => {
            markDirty();
            showMessage("Gespeichert");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Fragment>
            {selectedSystem &&
              <Edit initialSystemInfo={selectedSystem} saveAction={handleSave} submitting={submitting} setSubmitting={setSubmitting}/>}
        </Fragment>
    )
}