import {Route, Switch, useHistory} from "react-router-dom";
import {Overview} from "../view/shipping/Overview";
import {Edit} from "../view/shipping/Edit";
import {get, post} from "../js/api";
import {addDirtyListener, markDirty, showMessage} from "../js/main";
import {useEffect, useState} from "react";
import {UpdateShipping} from "../view/shipping/UpdateShipping";

export const Shipping = () => {

    const empty = {
        id: null,
        title: "",
        pickupAtStore: false,
        price: {}
    };

    const history = useHistory();

    const [shippings, setShippingMethods] = useState(null);

    const init = () => {
        get("/shippings").then(response => {
            setShippingMethods(response);
        });
    };

    useEffect(() => {
        init();
        addDirtyListener(init);
    }, []);

    const createShipping = (shippingInfo) => {
        post("/shippings", shippingInfo).then(() => {
            markDirty();
            showMessage("Versandoption wurde erstellt.");
            history.push("/versand");
        });
    };

    return (
        <Switch>
            <Route exact path="/versand">
                <Overview shippings={shippings}/>
            </Route>
            <Route exact path="/versand/bearbeiten/:shippingId">
                <UpdateShipping shippings={shippings}/>
            </Route>
            <Route exact path="/versand/neu">
                <Edit initialShippingInfo={empty} saveAction={createShipping}/>
            </Route>
        </Switch>
    );

};