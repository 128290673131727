import {CreateNewAction} from "../../component/CreateNewAction";
import {Link, useHistory} from "react-router-dom";
import {DataTable} from "../../component/datatable/DataTable";
import {BreadCrumb} from "../../component/Breadcrumb";
import {ReactComponent as CheckboxOff} from "../../img/icon/checkbox-off.svg";
import {useEffect, useRef, useState} from "react";
import {InputText} from "../../component/form/InputText";
import {ReactComponent as IconFile} from "../../img/icon/file.svg";
import {showError, showMessage} from "../../js/main";
import {Button} from "../../component/form/Button";
import {ReactComponent as IconDelete} from "../../img/icon/delete.svg";
import {InputFile} from "../../component/form/InputFile";
import Papa from "papaparse";
import {InputCheckbox} from "../../component/form/InputCheckbox";

const OverlayType = {
    FULL:"full",
    ISSUE:"issue",
    RETURN:"return"
}

export const LocksystemKeyEdit = ({locksystem, initialLocksystemKeys, canCreate, setPatch}) => {

    const empty = {name: "", issuedCount: 0, stockCount: 0, retiredCount: 0, orderedCount:0 , updateNote: ""};

    const [overlayOpen, setOverlayOpen] = useState(false);
    const [overlayType, setOverlayType] = useState(OverlayType.FULL);
    const [keyCount, setKeyCount] = useState(0);

    const [editLocksystemKey, setEditLocksystemKey] = useState(empty);
    const [creatingNew, setCreatingNew] = useState(false);
    const [locksystemKeys, setLocksystemKeys] = useState(initialLocksystemKeys);
    const [importedFile, setImportedFile] = useState(false);

    const scrollRef = useRef();

    useEffect(() => {
        setLocksystemKeys(initialLocksystemKeys);
    }, [initialLocksystemKeys])
    const columns = [
        {
            name: "Schlüsselnummer",
            selector: (row) => row.name,
            sortable: true,
            maxWidth: '400px',
        },
        {
            name: "Ausgegeben",
            selector: (row) => row.issuedCount,
            sortable: true,
        },
        {
            name: "Im Depot",
            selector: (row) => row.stockCount,
            sortable: true,
        },
        {
            name: "Ausgeschieden",
            selector: (row) => row.retiredCount,
            sortable: true,
        },
        {
            name: "in Bestellung",
            selector: (row) => row.orderedCount,
            sortable: true,
        },
        {
            name: "",
            selector: (row) => row.updateNote ? <IconFile style={{'fill':'var(--highlight)', 'width': '27px', "height": "auto"}}/> : "",
            sortable: true,
            maxWidth: '20px',
        },
        {
            name: "",
            selector: (row) => row.changed ?
                <a className={"no-link-hover"} onClick={() => handleRemove(row)}><IconDelete style={{'width': '27px'}}/></a> : "",
            sortable: true,
            maxWidth: '20px',
        },
        {
            name: "Aktion",
            selector: (row) => !row.changed ? <>
                    <Button onClick={(e) => openKeyMgmtOverlay(OverlayType.ISSUE, e, row)} theme={"mb-05"}>Ausgabe</Button>
                    <Button onClick={(e) => openKeyMgmtOverlay(OverlayType.RETURN,e, row)}>Rückgabe</Button>
            </>
                : "",
            sortable: true,
            style: {padding:10}
        }
    ];

    const openKeyMgmtOverlay = (type, e, row) => {
        e.preventDefault();
        setKeyCount(0);
        setOverlayType(type);
        setEditLocksystemKey(Object.assign({}, row)); //shallow works for us;
        setCreatingNew(false);
        setOverlayOpen(true);
    }

    const conditionalRowStyles = [
        {
            when: row => row.changed,
            style: ({color: 'var(--primary-text)', backgroundColor: 'var(--table-row-highlighted)'}),
        },
    ]

    const handleRowClick = (row) => {
        setEditLocksystemKey(Object.assign({}, row)); //shallow works for us;
        setCreatingNew(false);
        setOverlayType(OverlayType.FULL);
        setOverlayOpen(true);
    };

    useEffect(() => {
        if(overlayOpen){
            const element = scrollRef.current;
            const rect = element.getBoundingClientRect();
            if(rect.bottom > window.innerHeight){
                scrollRef.current.scrollIntoView(false);
            }
        }

    }, [overlayOpen, editLocksystemKey])

    const handleRemove = (row) => {
        let data;
        if (row.id) {
            data = [initialLocksystemKeys.find(r => r.id == row.id), ...locksystemKeys.filter(e => e.name != row.name)];
        } else {
            data = locksystemKeys.filter(e => e.name != row.name);
        }
        setLocksystemKeys(data);
        setPatch(data.filter(d => d.changed));
    }

    const handleNewClick = (e) => {
        e.preventDefault();
        setCreatingNew(true);
        setEditLocksystemKey(Object.assign({}, empty));
        setOverlayType(OverlayType.FULL);
        setOverlayOpen(true);
    }

    const handleOverlayClose = (e) => {
        e.preventDefault();
        setCreatingNew(false);
        setOverlayOpen(false);
    }

    const handleOverlaySafe = (e) => {
        e.preventDefault();

        if(overlayType == OverlayType.FULL){
            if ( !editLocksystemKey.name ||
                !editLocksystemKey.updateNote ||
                isNaN(editLocksystemKey.issuedCount) ||
                isNaN(editLocksystemKey.stockCount) ||
                isNaN(editLocksystemKey.orderedCount) ||
                isNaN(editLocksystemKey.retiredCount)) {
                showError("Bitte Eingabe überprüfen!");
                return;
            }
        }else{
            if(isNaN(keyCount) || keyCount <= 0 || !editLocksystemKey.updateNote){
                showError("Bitte Eingabe überprüfen!");
                return;
            }

            if(OverlayType.ISSUE == overlayType){
                if (keyCount > editLocksystemKey.stockCount){
                    showError("Es können nicht mehr Schlüssel ausgegeben werden als auf Lager sind.");
                    return;
                }
                editLocksystemKey.stockCount -= keyCount;
                editLocksystemKey.issuedCount += parseInt(keyCount);
            }
            if(OverlayType.RETURN == overlayType){
                if (keyCount > editLocksystemKey.issuedCount){
                    showError("Es können nicht mehr Schlüssel rückgenommen werden als ausgegeben sind.");
                    return;
                }
                editLocksystemKey.issuedCount -= keyCount;
                editLocksystemKey.stockCount += parseInt(keyCount);
            }
        }

        let updated = Object.fromEntries(Object.entries(editLocksystemKey));

        let data = [updated, ...locksystemKeys.filter(e => e.name != updated.name)];
        setLocksystemKeys(data);
        setPatch(data.filter(d => d.changed));
        setOverlayOpen(false);
    }

    const handleFieldUpdate = (newValue, field) => {
        let updatedInfo = Object.fromEntries(Object.entries(editLocksystemKey));
        updatedInfo[field] = newValue;
        updatedInfo.changed = true;
        setEditLocksystemKey(updatedInfo);
    }

    const handleImportFile = (file) => {
        if(file) {
            setImportedFile(true);
            Papa.parse(file, {
                header: false,
                skipEmptyLines: true,
                complete: function (results) {
                    const data = results.data;
                    let keyNumbers = [];
                    const mapped = data.map(d => {
                       const keyObj = Object.fromEntries(Object.entries(empty));
                       keyNumbers.push(d[0]);
                       keyObj.name = d[0];
                       keyObj.issuedCount = d[1] || 0;
                       keyObj.stockCount = d[2] || 0;
                       keyObj.retiredCount = d[3] || 0;
                       keyObj.orderedCount = d[4] || 0;
                       keyObj.changed = true;
                       keyObj.updateNote = "Import aus Datei";
                       return keyObj;
                    });
                    let display = [...mapped, ...locksystemKeys.filter(e => keyNumbers.indexOf(e.name) == -1)];
                    setLocksystemKeys(display);
                    setPatch(mapped);
                },
            });
        }
    }

    const [overlayWording, setOverlayWording] = useState();

    useEffect(() => {
        if(overlayType == OverlayType.FULL){
            setOverlayWording("bearbeiten");
        }
        if(overlayType == OverlayType.ISSUE){
            setOverlayWording("Ausgabe");
        }
        if(overlayType == OverlayType.RETURN){
            setOverlayWording("Rückgabe");
        }
    }, [overlayType]);
    const getOverlayWording = () => {

    }

    return (
        <div>
            <h2>Schlüssel</h2>

            <div className="data-table">
                <DataTable data={locksystemKeys}
                           actions={canCreate && <button onClick={handleNewClick}>{"Neuen Schlüssel hinterlegen"}</button>}
                           columns={columns}
                           nameField="name"
                           preventDeletingAll
                           onRowClicked={handleRowClick}
                           conditionalRowStyles={conditionalRowStyles}/>
            </div>

            {overlayOpen && <div className={"locksystem-keys-edit"} ref={scrollRef}>
                <h2>Schlüssel {overlayWording}</h2>
                {OverlayType.FULL == overlayType && <div className="default">
                    <InputText name={"name"} value={editLocksystemKey.name} label={"Schlüsselnummer"}
                               setter={handleFieldUpdate} required={true}
                               disabled={!(canCreate && creatingNew)}/>
                    <InputText name={"issuedCount"} value={editLocksystemKey.issuedCount} label={"Ausgegeben"}
                               setter={handleFieldUpdate} required={true} pattern={"[0-9]+"}/>
                    <InputText name={"stockCount"} value={editLocksystemKey.stockCount} label={"Im Depot"}
                               setter={handleFieldUpdate} required={true} pattern={"[0-9]+"}/>
                    <InputText name={"retiredCount"} value={editLocksystemKey.retiredCount} label={"Ausgeschieden"}
                               setter={handleFieldUpdate} required={true} pattern={"[0-9]+"}/>
                    <InputText name={"orderedCount"} value={editLocksystemKey.orderedCount} label={"In Bestellung"}
                               setter={handleFieldUpdate} required={true} pattern={"[0-9]+"}/>
                    <InputText name={"updateNote"} value={editLocksystemKey.updateNote} label={"Änderungsinfo"}
                               setter={handleFieldUpdate} required={true} pattern={"[0-9]+"}/>
                </div>}
                {OverlayType.FULL != overlayType && <div className="default">
                    <InputText name={"name"} value={editLocksystemKey.name} label={"Schlüsselnummer"}
                               setter={handleFieldUpdate} required={true}
                               disabled={true}/>
                    <InputText name={"keyCount"} value={keyCount} label={"Anzahl"}
                               setter={i => setKeyCount(i)} required={true} pattern={"[0-9]+"}/>
                    <InputText name={"updateNote"} value={editLocksystemKey.updateNote} label={"Änderungsinfo - Wird am Dokument angedruckt"}
                               setter={handleFieldUpdate} required={true} pattern={"[0-9]+"}/>
                </div>}

                <div className={"default"}>
                    <Button theme="secondary-button mr-1 mb-1" onClick={handleOverlayClose}>Abbrechen</Button>
                    <Button theme="primary-button mb-1" onClick={handleOverlaySafe} width={250}>Änderung
                        übernehmen</Button>
                </div>
            </div>}
            {canCreate && !importedFile && <div style={{'marginTop': '1em'}}>
                <InputFile name="keyImport" maxMBSize="10" accept=".csv" label="Schlüsselimport"
                           setter={handleImportFile}/>
            </div>}
        </div>
    );

};