export const InputTextarea = ({name, value, label, setter, required = false, readOnly = false, concatId = null, ...props}) => {

    const identifier = concatId ? name + concatId : name;

    return (
        <div className="form-input">
            {label &&
            <label htmlFor={identifier}>
                {label} {required && <span className="primary-link bold">*</span>}
            </label>}
            <textarea id={identifier}
                      value={value || ''}
                      onChange={e => setter(e.target.value, name)}
                      readOnly={readOnly}
                      required={required}
                      {...props}/>
        </div>
    );

};