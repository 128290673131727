export const InputType = ({name, value, label, type, setter, required = false, readOnly = false, concatId = null, ...rest}) => {

    const identifier = concatId ? name + concatId : name;

    return (
        <div className="form-input">
            {label &&
             <label htmlFor={identifier}>
                 {label} {required && <span className="primary-link bold">*</span>}
             </label>}

            <input id={identifier}
                   type={type}
                   value={value}
                   onChange={e => setter(e.target.value, name)}
                   readOnly={readOnly}
                   required={required}
                   {...rest}/>
        </div>
    );
};