export const customTheme = {
    text: {
        primary: "var(--primary-text)",
        secondary: "var(--secondary-text)",
    },
    background: {
        default: "transparent",
    },
    divider: {
        default: "var(--shade-4)",
    },
    sortFocus: {
        default: "var(--button-border-hover)",
    },
};

export const customStyles = {
    headCells: {
        style: {
            fontSize: "20px",
            fontWeight: "var(--boldness)",
            color: "var(--primary-link)",
        },
        activeSortStyle: {
            color: "var(--button-font-hover)",
            backgroundColor: "var(--button-background-hover)",
        },
    },

    rows: {
        style: {
            fontSize: "var(--font-size)",
            "&:hover": {
                color: "var(--primary-link)",
                backgroundColor: "var(--shade-2)",
                ".checkbox-replacer" : {
                    border: "var(--borderle) var(--primary-link)",
                },
            }
        },
        selectedHighlightStyle: {
            color: "var(--primary-link) !important",
            "&:hover": {
                backgroundColor: "var(--table-row-highlighted-hover)",
            },
            "&:nth-of-type(n)": {
                backgroundColor: "var(--table-row-highlighted)",
                borderBottomColor: "var(--shade-4)",
            },
        },
    },

    expanderButton: {
        style: {
            padding: 0,
        },
    },

    noData: {
        style: {
            fontStyle: "italic",
        },
    },

    pagination: {
        style: {
            color: 'var(--shade-1)',
            fontSize: '16px',
            minHeight: '56px',
            borderTopStyle: 'solid',
            borderTopWidth: '1px',
        },
        pageButtonsStyle: {
            height: '40px',
            width: '40px',
            padding: '8px',
            margin: 'px',
            cursor: 'pointer',
            transition: '0.4s',
            color: 'var(--button-font)',
            fill: "var(--button-font)",
            backgroundColor: 'transparent',
            borderRadius: "var(--border-radius)",
            transform: 'scale(1.1)',
            marginRight: '7px',
            top: '10px',
            '&:disabled': {
                cursor: 'unset',
                fill: "var(--shade-1)",
                backgroundColor: "var(--shade-2) !important",
                borderColor: "var(--shade-1) !important",
            },
            '&:hover:not(:disabled)': {
                fill: "var(--button-font-hover)",
                backgroundColor: "var(--button-background-hover)",
            },
            '&:focus:not(:disabled)': {
                fill: "var(--button-font-hover) !important",
                backgroundColor: "var(--button-background-hover) !important",
            },
            'svg' : {
                left: '-2px',
                top: '-2px',
                position: 'relative',
            }
        },
    },

};

