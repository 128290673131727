import {Fragment, useEffect, useState} from "react";
import {Layout} from "./Layout";
import {useParams} from "react-router-dom";
import {get, post, put} from "../../js/api";
import {OrderInformation} from "../OrderInformation";
import {Button} from "../form/Button";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "../CheckoutForm";
import {showError, showMessage} from "../../js/main";
import {mapState} from "../../view/orders/UpdateOrder";

const stripePromise = loadStripe("pk_live_v5b9Y756ztuwfVze0WyinUZC00NhKtj8CQ");
export const Payment = () => {

    const {paymentId, done} = useParams();

    const [payment, setPayment] = useState(null);
    const [clientSecret, setClientSecret] = useState();
    const [submitting, setSubmitting] = useState();

    const [message, setMessage] = useState('');

    useEffect(() => {
        get("/payments/"+encodeURIComponent(paymentId)).then(setPayment);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const requestClientSecret = () => {
        setSubmitting(true);
        post("/payments/"+encodeURIComponent(paymentId)+"/intents", null).then(setClientSecret).finally(() => setSubmitting(false));
    }

    useEffect(() => {
        if(done){
            const queryParams = new URLSearchParams(window.location.search);
            if(queryParams.get("redirect_status") == "succeeded"){
                put("/payments/"+encodeURIComponent(paymentId)+"/intents/state/PENDING", null).then(() => {
                    const message = "Vielen Dank, die Zahlung wird verarbeitet. Sie werden per E-Mail informiert";
                    showMessage(message);
                    setMessage(message);
                }).catch(() => {
                    const message = "Bei Verarbeitung der Zahlung ist ein Fehler aufgetreten.";
                    showError(message);
                    setMessage(message);
                });
            }else{
                const message = "Bei der Zahlung ist ein Fehler aufgetreten, bitte beachten Sie alle Meldungen und probieren Sie es bei Bedarf nochmals.";
                showError(message);
                setMessage(message);
            }
        }
    }, [done])

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: '#253646',
        },
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <Layout >
            {!payment && <Fragment>
                <h2 className="mt-1d5 mb-03">Ihre Bestellung wird geladen...</h2>
            </Fragment>}
            {payment && <Fragment>
                <OrderInformation order={payment.orderInfo} />
                <h2 className="mt-1d5 mb-03">Bezahlung</h2>
                <table>
                    <tr>
                        <td width="200">Status</td>
                        <td>{mapState(payment.paymentInfo.paymentState)}</td>
                    </tr>
                </table>
            </Fragment>
            }
            {payment && !done &&
                <Fragment>
                    {!clientSecret && (payment.paymentInfo.paymentState == 'AWAIT_USER' || payment.paymentInfo.paymentState == 'FAILED') &&
                    <Button submitting={submitting} width={250} onClick={requestClientSecret}>Bezahlung starten</Button>
                    }
                    {clientSecret &&
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    }
                </Fragment>
            }
            {payment && done && <Fragment>
                <p>{message}</p>
            </Fragment>}
        </Layout>
    );

};
