import Select from "react-select";
import Creatable from "react-select/creatable";
import {useEffect, useState} from "react";

export const SelectReact = ({name, value, options, label, setter, required = false, hidePleaseChoose = false, disabled = false, concatId = null, canAddNew = false, ...props}) => {

    const NULL_VALUE = "";

    const identifier = concatId ? name + concatId : name;

    const [valInternal, setValInternal] = useState();

    useEffect(() => {
        if(value instanceof Array){
            setValInternal(options.filter(o => value.indexOf(o.value) > -1));
        }else{
            setValInternal(options.find(o => o.value == value));
        }
    }, [value, options])

    const onChange = (e) => {
        if(e instanceof Array){
            setter(e.map(x => x.value), name);
        }else{
            setter(NULL_VALUE === e.value ? null : e.value, name)
        }
    }

    return (
        <div className="form-input">
            {label &&
             <label htmlFor={identifier}>
                {label} {required && <span className="primary-link bold">*</span>}
            </label>}

            {!canAddNew &&
                <Select id={identifier}
                        placeholder="Bitte wählen"
                        noOptionsMessage={() => "Keine Einträge vorhanden"}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={valInternal || NULL_VALUE}
                        onChange={onChange}
                        isDisabled={disabled} unstyled={true}
                        required={required} options={options} {...props}/>}
            {canAddNew &&
                <Creatable id={identifier}
                        placeholder="Bitte wählen"
                        noOptionsMessage={() => "Keine Einträge vorhanden"}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={valInternal || NULL_VALUE}
                        onChange={onChange}
                        isDisabled={disabled} unstyled={true}
                        required={required} options={options} {...props}/>
            }
        </div>
    );

};