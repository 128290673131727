import {get} from "../js/api";
import {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import {Overview} from "../view/orders/Overview";
import {UpdateOrder} from "../view/orders/UpdateOrder";
import {addDirtyListener} from "../js/main";

export const Orders = () => {

    const [orders, setOrders] = useState(null);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());

    const init = () => {
        get("/orders?year=" + selectedYear).then(resp => {
            setOrders(resp);
        });
    };

    useEffect(() => {
        init();
        addDirtyListener(init);
    }, [selectedYear]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Switch>
            <Route exact path="/bestellungen">
                <Overview orders={orders} selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
            </Route>
            <Route exact path="/bestellungen/bearbeiten/:orderId">
                <UpdateOrder orders={orders} adminView/>
            </Route>
        </Switch>
    )
}