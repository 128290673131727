import {Auth} from "aws-amplify";
import Bus from "./Bus";
import {AuthState} from "@aws-amplify/ui-components";

const globals = {}

export const globalKeys = {
    isAdmin: "isAdmin",
}

export const getGlobal = (key) => {
    return globals[key];
}

export const putGlobal = (key, value) => {
    globals[key] = value;
}

export const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const setSiteTitle = (title) => {
    document.title = title + "  | Key Direct"
};

export const getCookie = (name) => {
    return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
};

export const logout = async () => {
    await Auth.signOut();
    Bus.emit("loginstate", AuthState.SignedOut);
};

export const showMessage = (message) => {
    const type = "info";
    Bus.emit("message", ({message, type}));
}

export const showError = (message) => {
    const type = "error";
    Bus.emit("message", ({message, type}));
}

export const markDirty = () => {
    Bus.emit("cleanup", {});
}

// This method is designed only for one listener per page!
export const addDirtyListener = (f) => {
    Bus.removeAllListeners("cleanup") // otherwise we hold references to objects that are no longer in use
    Bus.addListener("cleanup", () => {
        f();
    });
}
