import {Route, Switch, useHistory} from "react-router-dom";
import {Edit} from "../view/key/Edit";
import {get, post, put} from "../js/api";
import {addDirtyListener, markDirty, showMessage} from "../js/main";
import {Overview} from "../view/key/Overview";
import {useEffect, useState} from "react";
import {UpdateKey} from "../view/key/UpdateKey";
import {BatchUpdateKey} from "../view/key/BatchUpdateKey";

export const Keys = () => {

    const history = useHistory();

    const [keys, setKeys] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    const empty = {
        id: null,
        itemNo: "",
        title: "",
        note: "",
        price: {}
    };

    const init = () => {
        get("/keys").then((response) => {
            setKeys(response);
        });
    }

    useEffect(() => {
        init();
        addDirtyListener(init);
    }, []);

    const createKey = (keyInfo) => {
        setSubmitting(true);
        post("/keys", keyInfo).then(() => {
            markDirty();
            showMessage("Schlüssel wurde erstellt.");
            history.push("/schluessel");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const saveAllKeys = (keyInfos) => {
        setSubmitting(true);
        const expectedRequests = keyInfos.size;
        let successfullReq = 0;
        const successWrapper = (respPromise) => {
            respPromise.then(() => {
                successfullReq++;
                if (successfullReq === expectedRequests) {
                    markDirty();
                    showMessage("Gespeichert");
                    history.push("/schluessel/");
                }
            }).finally(() => {
                setSubmitting(false);
            });
        }

        keyInfos.forEach(key => {
            successWrapper(put("/keys/" + key.id, key));
        })
    };

    return (
        <Switch>
            <Route exact path="/schluessel">
                <Overview keys={keys}/>
            </Route>
            <Route exact path="/schluessel/edit/:keyId">
                <UpdateKey keys={keys}/>
            </Route>
            <Route exact path="/schluessel/batch/:keyIds">
                <BatchUpdateKey keys={keys} saveAction={saveAllKeys} submitting={submitting}/>
            </Route>
            <Route exact path="/schluessel/neu">
                <Edit initialKeyInfo={empty} saveAction={createKey} submitting={submitting} setSubmitting={setSubmitting}/>
            </Route>
        </Switch>
    );

};