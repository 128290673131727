import {BreadCrumb} from "../../component/Breadcrumb";

export const Widerrufsrecht = () => {

    return (
        <div>
            <BreadCrumb currentTitle="Impressum"/>

            <h2>Rücksendungen</h2>
            <p>Um unseren Service und unser Angebot für Sie laufend verbessern zu können, würden wir uns besonders freuen, wenn Sie den Retouren-Grund angeben. Dies ist für die Inanspruchnahme der freiwilligen Rücksendung jedoch nicht erforderlich. Sie haben die Möglichkeit Ihre auf dem gleichen Wege wie sie ursprünglich erfolgt ist zurückzugeben. Die Rückerstattung erfolgt auf dem gleichen Wege wie die Bezahlung erfolgt ist und beträgt maximal 14 Tage.</p>
            <p>Die Gewährleistung erfolgt nach den gesetzlichen Bestimmungen. Bei allen während der gesetzlichen Gewährleistungsfrist von zwei Jahren ab Lieferung auftretenden Mängeln haben Sie das gesetzliche Recht auf Nacherfüllung (nach Ihrer Wahl: Mangelbeseitigung oder Neulieferung) und - bei Vorliegen der gesetzlichen Voraussetzungen - die gesetzlichen Rechte auf Minderung oder Rücktritt sowie daneben auf Schadensersatz.</p>
            <h2>Widerrufsrecht</h2>
            <p>Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns, Sicherheitsshop SafeINN, Innsbrucker Straße 50, 6130 Schwaz, <a href="tel:+43524262259">+43 5242 62259</a>, <a href={"mailto:sicherheit@lintner.at"}>sicherheit@lintner.at</a> mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Sie können dafür das beigefügte Muster-Widerrufsformular verwenden, das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.</p>
            <p>Folgen des Widerrufs: Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist.</p>
            <p>Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. Wir können die Rückzahlung verweigern, bis wir die Waren wieder zurückerhalten haben oder bis Sie den Nachweis erbracht haben, dass Sie die Waren zurückgesandt haben, je nachdem, welches der frühere Zeitpunkt ist. Sie haben die Waren unverzüglich und in jedem Fall spätestens binnen vierzehn Tagen ab dem Tag, an dem Sie uns über den Widerruf dieses Vertrags unterrichten, an Lintner Sicherheitstechnik GmbH, Innsbrucker Straße 49, 6130 Schwaz zurückzusenden oder zu übergeben. Die Frist ist gewahrt, wenn Sie die Waren vor Ablauf der Frist von vierzehn Tagen absenden. Wir tragen die Kosten der Rücksendung der Waren</p>
        </div>
    );

};