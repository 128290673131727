import {useHistory} from "react-router";
import {DataTable} from "../../component/datatable/DataTable";
import {CreateNewAction} from "../../component/CreateNewAction";
import {formatPrice} from "../../js/util";
import {BreadCrumb} from "../../component/Breadcrumb";
import {Fragment} from "react";
import {ReactComponent as CheckboxOn} from "../../img/icon/checkbox-on.svg";


export const Overview = ({shippings}) => {

    const history = useHistory();

    const columns = [
        {
            name: "Name",
            selector: (row) => row.title,
            sortable: true
        },
        {
            name: "Abholoption",
            selector: (row) =>
                <Fragment>
                    {row.pickupAtStore && <CheckboxOn style={{'width': '32px'}}/>}
                </Fragment>,
            sortable: true,
        },
        {
            name: "Preis € (netto)",
            selector: (row) => formatPrice(row.price),
            right: true,
            sortable: true,
            sortFunction: (first, second) => first.price - second.price,
        },
    ];

    const handleRowClick = (row) => {
        history.push("/versand/bearbeiten/" + row.id);
    };

    return (
        <div>
            <BreadCrumb currentTitle="Versand"/>

            <div className="data-table">
                <DataTable data={shippings}
                           columns={columns}
                           actions={<CreateNewAction buttonText="Versandoption hinzufügen" redirect="/versand/neu"/>}
                           selectableRows
                           deleteApi="/shipping/"
                           url="/versand"
                           preventDeletingAll
                           onRowClicked={handleRowClick}/>
            </div>
        </div>
    );

};