import {NavLink} from "react-router-dom";
import {Fragment} from "react";

export const NavAdmin = () => {

    return (
        <Fragment>
            <NavLink to="/bestellungen" activeClassName="current">Bestellungen</NavLink>
            <NavLink to="/benutzer" activeClassName="current">Benutzer</NavLink>
            <NavLink to="/schliessanlagen" activeClassName="current">Schließanlagen</NavLink>
            <NavLink to="/schluessel" activeClassName="current">Schlüssel</NavLink>
            <NavLink to="/versand" activeClassName="current">Versand</NavLink>
        </Fragment>
    );

}