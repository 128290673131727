import {DataTable} from "./datatable/DataTable";
import {formatPrice, sumItemsDouble} from "../js/util";
import {OrderTotal} from "./OrderTotal";

export const OrderShippingAndTotal = ({orderItems, shipping}) => {

    const shippingColumn = [
        {
            name: "Versand: " + shipping.title,
            right: true,
            selector: (row) => formatPrice(shipping.price),
        }
    ];

    return (
        <div className="right">
            <div className="data-table mt-0 no-hover inline-block" style={{ width: '400px', maxWidth: '100%'}}>
                <DataTable data={[{}]}
                           columns={shippingColumn}
                           noHeader
                           noSearch
                           removeTopPadding/>
            </div>
            <br/>
            <OrderTotal orderItems={orderItems} shipping={shipping}/>
        </div>
    );

};