import React, {Component, Fragment} from "react";
import Switch from "react-switch";

import {ReactComponent as IconSun} from "../img/icon/sun.svg";
import {ReactComponent as IconMoon} from "../img/icon/moon.svg";

import {getCookie} from "../js/main";


class DarkThemeToggle extends Component {

    constructor(props) {
        super(props);

        let checked;
        // cookie set?
        let cookie = getCookie("darkTheme");
        if (cookie !== "") {
            checked = cookie === "true";
        } else {
            // os preference?
            const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
            checked = prefersDarkScheme.matches;
        }

        this.state = {checked: checked};
        this.handleChange = this.handleChange.bind(this);
        this.changeColor(checked);
    }

    handleChange(active) {
        this.setState({checked: active});
        this.changeColor(active);
        document.cookie = "darkTheme=" + active + "; SameSite=Strict; Path=/";
    }

    changeColor(active) {
        if (active) {
            document.querySelector(":root").classList.add("dark-theme");
        } else {
            document.querySelector(":root").classList.remove("dark-theme");
        }
    }


    render() {
        return (
            <Fragment>
                <style>{`
                    .moon {
                        position: relative;
                        left: 73px;
                        height: 27px;
                        top: -4px;
                    }
                    .sun {
                        position: relative;
                        right: 72px;
                        top: -5px;
                    }
                `}</style>

                <Switch onChange={this.handleChange}
                        checked={this.state.checked}
                        aria-label="Farbschema wechseln"
                        checkedIcon={<IconMoon className="moon"/>}
                        uncheckedIcon={<IconSun className="sun"/>}
                        height={20}
                        onColor="#666"
                        offColor="#ccc"
                        onHandleColor="#ccc"
                        offHandleColor="#666"
                />
            </Fragment>
        );
    }

}

export default DarkThemeToggle;