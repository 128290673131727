import {RequestInformation} from "../guestRequest/RequestInformation";
import {OrderUpdate} from "../../component/OrderUpdate";
import {OrderInformation} from "../../component/OrderInformation";


export const UpdateQrOrder = ({orderInfo, signed}) =>{

    return(<>
        <h1>Bestellung bestätigen</h1>
        <OrderUpdate order={orderInfo} signed={signed} canceled={() => {}} tanFailed={() => {}}
                     isPublic={true}>
            <OrderInformation order={orderInfo} showUser={false}/>
        </OrderUpdate>
    </>)

}