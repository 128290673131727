import {get} from "../../js/api";
import {sleep} from "../../js/main";

// console.log("initializing KeyCache.js");

let keys = null;
let isFetching = false;

export const invalidateKeysCache = () => {
    keys = null;
};

export const getKeysCache = async () => {
    // console.log("hi");

    try {
        while (isFetching) {
            await sleep(200);
        }

        if (!keys) {
            isFetching = true;
            // console.log("no cache present ... fetching");
            const response = await get("/keys");
            keys = new Map();
            response.forEach(key => keys.set(parseInt(key.id), key));
            // console.log("fetching done");
        }
    } finally {
        isFetching = false;
    }

    // console.log("returning keys");

    return keys;
};

