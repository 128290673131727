export const formatPrice = (number) => {
    return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(number);
};

export const roundNumber = (number) => {
    return Math.round(number * 1e2) / 1e2;
}

export const applyDiscount = (price, discount) => {
    if (discount) {
        return roundNumber(price * (100 - discount) / 100.0);
    } else {
        return price;
    }
};

export const sumItemsDouble = (items) => {
    let price = 0.0;
    items.forEach(i => price += applyDiscount(i.price, i.discount) * i.amount);
    return price;
}

export const sumItems = (items) => {
    return formatPrice(sumItemsDouble(items));
}

export const germanDate = (timestamp) => {
    const creationDate = new Date(timestamp);
    const day = ('0' + creationDate.getDate()).slice(-2);
    const month = ('0' + (creationDate.getMonth() + 1)).slice(-2);
    const year = creationDate.getFullYear();

    return `${day}.${month}.${year}`;
}

export const germanDateTime = (timestamp) => {
    const creationDate = new Date(timestamp);
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h24'
    };

    return creationDate.toLocaleDateString('de-DE', options);
}

export const isTouchDevice = () => {
    return window.matchMedia("(pointer: coarse)").matches
}

export const deepCopy = (obj) => {
    return Object.fromEntries(Object.entries(obj))
}

export class SuccessWrapper {

    constructor() {
        this.promises = [];
    }

    add(promise) {
        this.promises.push(promise);
    }

    waitForSuccess(callback) {
        return Promise.all(this.promises).then(callback);
    }
}


export const performDownloadS3 = (s3Uri) => {
    let frame = document.createElement("iframe");
    frame.src = parseS3Uri(s3Uri);
    frame.style.display = "none";

    var prom = new Promise((resolve, reject) => {
        // see https://stackoverflow.com/questions/6126619/which-js-event-is-fired-when-chrome-gets-the-download-file/6245998#6245998
        const verifyDownload = () => {
            if(frame.contentWindow.document.getElementsByName("body").length >= 1){
                console.error("Failed to download, iframe has content");
                return false;
            }else{
                return true;
            }
        }
        //this is a nice idea, but honestly overengineered. We're not leaving the page anyway with history.push
        const checkResolveable = () => {
            if(!verifyDownload()){
                reject();
            }else{
                resolve();
            }
        }
        setTimeout(checkResolveable, 1000);
    })

    document.body.append(frame);
    return prom;
}

export const parseS3Uri = (uri) => {
    //because javascript regex are horrible
    const toParse = uri.split("/");
    if(toParse[0] != "s3:"){
        throw "Not valid s3 Uri";
    }
    const bucket = toParse[2];
    const path = toParse.slice(3, toParse.length).join("/");

    return `https://${bucket}.s3.eu-central-1.amazonaws.com/${path}`;
}

export const corsFetch = (uri, init = {}) => {
    init["mode"] = "cors";
    init["cache"] = "no-store"; //https://stackoverflow.com/questions/16797730/origin-header-missing-from-cross-origin-request-to-s3-on-chrome
    return fetch(uri, init);
}