import {useEffect, useState} from "react";
import {InputText} from "./InputText";
import {InputType} from "./InputType";

export const empty = {
    // type: ""     // will be handled differently, set from outside
    firstName: "",
    lastName: "",
    company: "",
    companyAdditional: "",
    street: "",
    postcode: "",
    city: "",
    phoneNumber: "",
    email: ""
};

export const AddressInput = ({initial, setter, type, optionals = false}) => {

    const [address, setAddress] = useState(initial);

    useEffect(() => {
        setAddress(initial);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        handleUpdate(type, "type");
    }, [type]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdate = (newValue, field) => {
        let updated = Object.fromEntries(Object.entries(address));
        updated[field] = newValue;
        setAddress(updated);
        setter(updated);
    };

    return (
        <div className="default">
            <InputText name="firstName" value={address.firstName} label="Vorname" setter={handleUpdate} required/>
            <InputText name="lastName" value={address.lastName} label="Nachname" setter={handleUpdate} required/>
            <InputText name="company" value={address.company} label="Firma" setter={handleUpdate}/>
            <InputText name="companyAdditional" value={address.companyAdditional} label="Firma Zusatz" setter={handleUpdate}/>
            <InputText name="street" value={address.street} label="Straße" setter={handleUpdate} required/>
            <InputText name="postcode" value={address.postcode} label="PLZ" setter={handleUpdate} required/>
            <InputText name="city" value={address.city} label="Stadt" setter={handleUpdate} required/>
            <InputText name="phoneNumber" value={address.phoneNumber} label="Telefonnummer" setter={handleUpdate} required={!optionals}/>
            <InputType name="email" value={address.email} label="E-Mail" type="email" setter={handleUpdate} required={!optionals}/>
        </div>
    );

};