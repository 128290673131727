import {Edit} from "../view/user/Edit.js";
import {get, post} from "../js/api";
import {Overview} from "../view/user/Overview";
import {Route, Switch, useHistory} from "react-router-dom";
import {addDirtyListener, markDirty, showError, showMessage} from "../js/main";
import {useEffect, useState} from "react";
import {UpdateUser} from "../view/user/UpdateUser";
import {uploadFileForUser} from "./Profile";

export const path = "/customers";

export const empty = {
    id: "",
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    gender: "",
    company: "",
    companyAdditional: "",
    street: "",
    postcode: "",
    city: "",
    phoneNumber: "",
    userGroup: null,
    activated: true
};

export const User = () => {

    const history = useHistory();

    const [users, setUsers] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    const init = () => {
        get(path).then((customers) => {
            setUsers(customers);
        })
    };

    useEffect(() => {
        init();
        addDirtyListener(init);
    }, []);

    const createUser = (userInfo, file) => {
        get(path + "?username=" + encodeURIComponent(userInfo.username) + "&findDeleted=true").then(response => {
            if (response.length === 0) {
                // does not exist, so we can create it
                post(path, userInfo).then(() => {
                    markDirty();
                    showMessage("User erstellt");
                    history.push("/benutzer");
                }).then(async () => {
                    await uploadFileForUser(userInfo.username, file);
                }).finally(() => {
                    setSubmitting(false);
                });
            } else {
                showError("Username bereits vergeben");
            }
        }).catch(() => {
            setSubmitting(false);
        });
    };

    return (
        <Switch>
            <Route exact path="/benutzer">
                <Overview users={users}/>
            </Route>
            <Route exact path="/benutzer/edit/:selectedUsername">
                <UpdateUser users={users}/>
            </Route>
            <Route exact path="/benutzer/neu/">
                <Edit initialUserInfo={empty} saveAction={createUser} submitting={submitting} setSubmitting={setSubmitting}/>
            </Route>
        </Switch>
    );

};