import {showError} from "../../js/main";

export const verify = (accept, maxMBSize, e) => {
    return new Promise((resolve, reject) => {

        let file = e.target.files[0];
        if(file){
            if(maxMBSize != -1 && (e.target.files[0].size) > maxMBSize*Math.pow(2,20)){
                showError("Dateigröße von "+maxMBSize+"MB darf nicht überschritten werden!");
                reject();
            }
            if(accept && !accept.split(",").map(a => a.replace("*", "")).some(a => {
                if(a.startsWith(".")){
                    return file.name.indexOf(a) > -1;
                }
                return file.type.indexOf(a) > -1
            })){
                showError("Ungültiger Dateityp");
                reject();
            }
            if(e.target.files.length > 1){
                showError("Es darf nur eine Datei gewählt werden!");
                reject();
            }
        }
        resolve(file);
    })
}

export const InputFile = ({name, label, setter, accept, maxMBSize = -1, required = false, disabled = false, concatId = null, innerRef}) => {

    const identifier = concatId ? name + concatId : name;

    return (
        <div className="form-input">
            {label &&
            <label htmlFor={identifier}>
                {label} {required && <span className="primary-link bold">*</span>}
            </label>}
            <input id={identifier}
                   type="file"
                   onChange={e => verify(accept, maxMBSize, e).then((f) => setter(f, name))}
                   disabled={disabled}
                   required={required}
                   accept={accept}
                   ref={innerRef}/>
        </div>
    );

};