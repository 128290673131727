export const InputPrice = ({name, value, label, setter, required = false, disabled = false, concatId = null}) => {

    const identifier = concatId ? name + concatId : name;

    return (
        <div className="form-input">
            {label &&
             <label htmlFor={identifier}>
                 {label} {required && <span className="primary-link bold">*</span>}
             </label>}

            <input id={identifier}
                   type="number"
                   min="0.00"
                   max="10000.00"
                   step="0.01"
                   value={value === 0 ? 0 : (value || '')}
                   disabled={disabled}
                   onChange={e => setter(e.target.value, name)}
                   required={required}/>
        </div>
    );

};