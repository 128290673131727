import {ReactComponent as IconCog} from '../../img/icon/cog.svg';
import {useEffect, useRef} from "react";

/**
 * themes:
 * - "" (default)
 * - green
 * - red
 * - orange
 */
export const Button = ({children, type = "submit", theme = "", submitting, width = 120, ...rest}) => {

    const submitButton = useRef();

    useEffect(() => {
        if (submitting) {
            submitButton.current.disabled = true;
            submitButton.current.style.width = (width + 30) + 'px';
        } else {
            submitButton.current.removeAttribute("disabled");
        }
    }, [submitting]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <button type={type}
                ref={submitButton}
                className={'submit-button ' + theme}
                style={{width: width + 'px'}}
                {...rest}>
            <main>
                <IconCog/>
                <span>{children}</span>
            </main>
        </button>
    );

};