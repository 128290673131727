import {Fragment, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {BreadCrumb} from "../../component/Breadcrumb";
import {get, post} from "../../js/api";
import {markDirty, showMessage} from "../../js/main";
import {OrderInformation} from "../../component/OrderInformation";
import {Button} from "../../component/form/Button";
import {InputText} from "../../component/form/InputText";
import {germanDate} from "../../js/util";


export const mapState = paymentState => {
    if (paymentState == "AWAIT_USER") {
        return "Bezahlung offen";
    } else if (paymentState == "PENDING") {
        return "Bestätigung durch Stripe ausständig";
    } else if (paymentState == "SUCCESS") {
        return "Bezahlt";
    } else if (paymentState == "FAILED") {
        return "Fehlgeschlagen";
    } else if (paymentState == "CANCELED"){
        return "Storniert";
    }
    return "Unbekannt";
}
export const UpdateOrder = ({orders}) => {

    const {orderId} = useParams();

    const [selectedOrder, setSelectedOrder] = useState();
    const [paymentInfo, setPaymentInfo] = useState();
    const [referencingOrderInfo, setReferencingOrderInfo] = useState();
    const [isSubmitting, setSubmitting] = useState(false);


    useEffect(() => {
        if (orders) {
            setSelectedOrder(orders.find(o => o.id === parseInt(orderId)));
        }
        get("/orders/" + orderId + "?detailed=true").then(detailed =>{
            setSelectedOrder(detailed.orderInfo);
            setPaymentInfo(detailed.paymentInfo);
            setReferencingOrderInfo(detailed.referencingOrderInfo);
        });
    }, [orders, orderId]); // eslint-disable-line react-hooks/exhaustive-deps

    const history = useHistory();

    const acceptOrder = () => {
        setSubmitting(true);
        post("/orders/" + orderId + "/acceptOrder").then(() => {
            markDirty();
            showMessage("Die Bestellung wurde als \"in Bearbeitung\" markiert.");
            history.push("/bestellungen");
        }).finally(() => {
            setSubmitting(false);
        });
    }

    const completeOrder = () => {
        setSubmitting(true);
        post("/orders/" + orderId + "/completeOrder").then(() => {
            markDirty();
            showMessage("Die Bestellung wurde erfolgreich abgeschlossen.");
            history.push("/bestellungen");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const cancelOrder = () => {
        if (!window.confirm("Wirklich stornieren?")) {
            return;
        }
        setSubmitting(true);
        post("/orders/" + orderId + "/cancelOrder").then(() => {
            markDirty();
            showMessage("Die Bestellung wurde erfolgreich storniert.");
            history.push("/bestellungen");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const cancelable_states = ["CREATED", "OPEN", "IN_PROGRESS", "AWAIT_PAYMENT"];

    const getButtons = ({state}) => {
        const content = [];

        if (state === "IN_PROGRESS") {
            content.push(<Button key="complete" theme="green mr-1 mb-1" width={230} submitting={isSubmitting}
                                 onClick={completeOrder}>Bestellung abschließen</Button>);
        } else if (state === "OPEN") {
            content.push(<Button key="accept" theme="green mr-1 mb-1" width={230} submitting={isSubmitting}
                                 onClick={acceptOrder}>Bestellung annehmen</Button>)
        } else if (state === "AWAIT_PAYMENT") {
            content.push(<Button key="accept" theme="green mr-1 mb-1" width={230} submitting={isSubmitting}
                                 onClick={() => {
                                     if(window.confirm("Übernehmen dieser Bestellung führt zu einem Abbruch der Kundenbezahlung per Bezahllink. Trotzdem übernehmen?")){
                                         acceptOrder();
                                     }
                                 }}>Bestellung annehmen</Button>)
        }

        if (cancelable_states.includes(state)) {
            content.push(<Button key="cancel" theme="red mr-1 mb-1" width={230} submitting={isSubmitting}
                                 onClick={cancelOrder}>Bestellung stornieren</Button>);
        }

        return content;
    }

    return (
        <Fragment>
            {selectedOrder && <div>
                <BreadCrumb currentTitle={"Bestellung " + orderId} parentTitle="Bestellungen"
                            parentLink="/bestellungen"/>

                {getButtons(selectedOrder)}

                <OrderInformation order={selectedOrder} referencingOrderInfo={referencingOrderInfo} showUser/>
                {paymentInfo && <>
                    <h2 className="mt-1d5 mb-03">Bezahlinformationen</h2>

                    <table>
                        <tr>
                            <td width="200">Bezahlung durch</td>
                            <td>{paymentInfo.payer}</td>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>{mapState(paymentInfo.paymentState)}</td>
                        </tr>
                        {paymentInfo.intentId &&
                            <tr>
                                <td>Stripe Intent-ID</td>
                                <td>{paymentInfo.intentId}</td>
                            </tr>}
                        {paymentInfo.intentResponseDate && <tr>
                            <td>Stripe Bestätigungszeitpunkt</td>
                            <td>{germanDate(paymentInfo.intentResponseDate)}</td>
                        </tr>}
                    </table>
                </>}
            </div>}
        </Fragment>
    );

}