import {useState} from "react";
import {InputText} from "../../component/form/InputText";
import {InputTextarea} from "../../component/form/InputTextarea";
import {InputPrice} from "../../component/form/InputPrice";
import {BreadCrumb} from "../../component/Breadcrumb";
import {Button} from "../../component/form/Button";

export const Edit = ({initialKeyInfo, saveAction, submitting, setSubmitting}) => {

    const [keyInfo, setKeyInfo] = useState(initialKeyInfo);

    const handleChange = (newValue, field) => {
        let updated = Object.fromEntries(Object.entries(keyInfo));
        updated[field] = newValue;
        setKeyInfo(updated);
    };

    return (
        <div>
            <BreadCrumb currentTitle={'Schlüssel ' + (keyInfo.id === null ? 'hinzufügen' : 'bearbeiten')}
                        parentTitle="Schlüssel"
                        parentLink="/schluessel"/>

            <form onSubmit={(e) => {
                e.preventDefault();
                setSubmitting(true);
                saveAction(keyInfo);
            }}>
                <div className="default">
                    <InputText name="itemNo" value={keyInfo.itemNo} label="Artikelnummer" setter={handleChange} />
                    <InputText name="title" value={keyInfo.title} label="Bezeichnung" setter={handleChange} required/>
                    <InputPrice name="price" value={keyInfo.price} label="Preis € (netto)" setter={handleChange} required/>
                    <InputTextarea name="note" value={keyInfo.note} label="Notiz" setter={handleChange}/>
                </div>
                <div className={"safe-actions"}>
                    <Button key="back" theme="secondary-button mr-1 mb-1" width={230} submitting={submitting} onClick={e => {e.preventDefault(); window.history.back()}}>zurück</Button>
                    <Button key="submit" theme="primary-button mb-1" width={230} submitting={submitting}>speichern</Button>
                </div>
            </form>
        </div>
    );

};