import {Layout} from "../../component/layout/Layout";
import {useEffect, useRef, useState} from "react";
import {AddressInput, empty as emptyAddress} from "../../component/form/AddressInput";
import {Button} from "../../component/form/Button";
import {BreadCrumb} from "../../component/Breadcrumb";
import {post} from "../../js/api";
import {showError, showMessage} from "../../js/main";
import {OrderUpdate} from "../../component/OrderUpdate";
import {OrderInformation} from "../../component/OrderInformation";
import {orderType} from "../../js/constants";
import {InputType} from "../../component/form/InputType";
import {deepCopy, germanDateTime} from "../../js/util";
import {InputImageFancy} from "../../component/form/InputImageFancy";

import exampleFrontside from "../../img/example-frontside.jpg";
import exampleBackside from "../../img/example-backside.jpg";
import {getOrderStateTag} from "../myOrders/Overview";
import {Link} from "react-router-dom";
import {RequestInformation} from "./RequestInformation";
import {InputText} from "../../component/form/InputText";
import {InputTextarea} from "../../component/form/InputTextarea";


export const GuestRequest = () => {

    const [billingAddress, setBillingAddress] = useState(emptyAddress);
    const [info, setInfo] = useState({frontImage: undefined, backImage: undefined, authorization:undefined, additionalInfo:undefined});
    const [submittable, setSubmittable] = useState(true);
    const [orderInfo, setOrderInfo] = useState(null);
    const [done, setDone] = useState(false);
    const [showBanner, setShowBanner] = useState(false);
    const [hasShownBanner, setHasShownBanner] = useState(false);
    const uniqueRef = useRef(new Set());

    const handleFile = (f, name) => {
        handleUpdate(f, name);
    }
    const handleUpdate = (newValue, field) => {
        let updated = Object.fromEntries(Object.entries(info));
        updated[field] = newValue;
        setInfo(updated)
    };
    useEffect(() => {

    }, []);

    const [submitting, setSubmitting] = useState(false);

    const uploadImage = async (side, file) => {
        const date = new Date().getTime();
        const filename = `Fotoupload_${date}_${side}${file.name.substring(file.name.indexOf("."))}`;
        const resp = await post("/public/s3/generateUpload", {
            "folder": "orderImages",
            "fileName": filename,
            "fileType": file.type
        });
        await fetch(resp.httpUploadUri, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Disposition": "attachment; filename*= UTF-8''" + filename
            }
        });

        return resp.s3identifier;
    }
    const createOrder = async () => {
        setSubmitting(true);
        try {
            const front = await uploadImage("vorne", info.frontImage);
            const back = await uploadImage("hinten", info.backImage);
            let authorization;
            if(info.authorization){
                authorization = await uploadImage("Sicherungskarte", info.authorization);
            }


            const item = await post("/guestRequests/createNewOrderItem", {
                frontImage: front,
                backImage: back,
                authorization: authorization,
                additionalInfo: info.additionalInfo
            }, true);

            const orderReq = {
                summary: "Fotobestellung Schlüssel für " + billingAddress.email,
                shippingInfo: undefined,
                billingAddress: billingAddress,
                paymentType: undefined,
                type: orderType.request,
                items: [item]
            };

            await post("/public/orders", orderReq, true).then(o => {
                showMessage("Anfrage gespeichert. Sie haben eine Mail mit dem Bestätigungscode erhalten.");
                setOrderInfo(o);
            });

        } finally {
            setSubmitting(false);
        }
    }

    const signed = () => {
        orderInfo.state = "OPEN";
        setOrderInfo(orderInfo);
        setDone(true);
        //handle payment
    }

    const showInfoOverlay = (e) => {
        if(hasShownBanner){
            return;
        }
        e.preventDefault();
        setShowBanner(true);
        setHasShownBanner(true);
    }

    const doNothing = () => {
    }

    return (
        <Layout username={null}>
            <BreadCrumb currentTitle="Anfrage: Schlüsselnachbestellung"/>
            {!done && <>
                {orderInfo == null && <>
                    <form onSubmit={e => {
                        e.preventDefault();
                        createOrder();
                    }}>
                        <h2 className="mt-2 mb-05">Schlüsselbilder hochladen</h2>
                        <p>
                            Einfach ein Foto machen, hochladen, Ihre Mailadresse eingeben und wir klären die Details. <br/>
                            <strong>Tipp:</strong> Wählen Sie einen dunklen Hintergrund und achten Sie darauf, dass die
                            Schlüsselnummer sichtbar ist.
                        </p>
                        <div className="default">
                            <InputImageFancy label={"Schlüssel Vorderseite"} name={"frontImage"} maxMBSize="10" setter={handleFile} required onClick={showInfoOverlay} uniqueRef={uniqueRef}/>
                            <InputImageFancy label={"Schlüssel Rückseite"} name={"backImage"} maxMBSize="10" setter={handleFile} required onClick={showInfoOverlay} uniqueRef={uniqueRef}/>
                            <InputImageFancy label={"Optional: Sicherungskarte / Schlüsselberechtigung (falls vorhanden)"} name={"authorization"} setter={handleFile} uniqueRef={uniqueRef}/>
                        </div>
                        <div className="default">
                            <div>
                                <p><strong>Alles was wir sonst noch wissen sollten:</strong></p>
                                <InputTextarea label={"Schlüsselnummer (falls möglich), Anzahl, ..."}
                                               name={"additionalInfo"} value={info.additionalInfo} setter={handleUpdate}
                                               rows={3}/>
                            </div>
                        </div>
                        {showBanner && <>
                            <div className={"background-blur"}></div>
                            <div className={"upload-info-banner"} onClick={() => setShowBanner(false)}>
                                <h2>Tipps für den Bildupload</h2>
                                <p>
                                Wählen Sie bitte einen gleichmäßigen Hintergrund und legen Sie im besten Fall als Größenreferenz noch eine 2-Euro-Münze neben den Schlüssel.<br/> Beispiel:
                                </p>
                                <div className={"example-container"}>
                                    <img src={exampleFrontside}/> <img src={exampleBackside}/>
                                </div>
                                <h3>Zum Schließen dieser Information einfach darauf klicken/tippen</h3>
                        </div></>}
                        <h2 className="mt-2 mb-05">Kontaktinformation</h2>

                        <InputType name="email" value={billingAddress.email} label="E-Mail" type="email" setter={(e) => {
                            let ba = deepCopy(billingAddress);
                            ba.email = e;
                            setBillingAddress(ba);
                        }} required={true}/>

                        <p>
                            Nach Anklicken des Buttons „Anfrage absenden“ erhalten Sie einen Verifizierungscode,
                            mit dem Sie Ihre unverbindliche Anfrage für eine Schlüsselnachbestellung bestätigen können. Dies dient zur Verifikation der E-Mail Adresse.
                            Unser Team wird Ihnen dann ein Mail mit Informationen und Preis  senden oder sich gegebenenfalls bei Fragen bei Ihnen melden - VIELEN DANK
                        </p>
                        <div className={"safe-actions mt-1"}>
                            <Button key="back" theme="secondary-button mr-1 mb-1" width={230}
                                    submitting={submitting} onClick={e => {
                                e.preventDefault();
                                window.history.back()
                            }}>zurück</Button>
                            <Button key="submit" theme="primary-button mb-1" width={230}
                                    submitting={submitting}>Anfrage absenden</Button>
                        </div>
                    </form>
                </>}

                {orderInfo != null && <>
                    <OrderUpdate order={orderInfo} signed={signed} canceled={doNothing} tanFailed={doNothing}
                                 isPublic={true}>
                        <RequestInformation order={orderInfo}/>
                    </OrderUpdate>
                </>}
            </>}


            {done && <>
                <p>
                    Vielen Dank für Ihre Anfrage! Wir werden uns umgehend bei Ihnen melden.
                </p>

                <RequestInformation order={orderInfo}/>

                <div className={"safe-actions mt-1"}>
                    <Button key="submit" theme="primary-button mb-1" width={230} onClick={e => {
                        e.preventDefault();
                        window.location.reload();
                    }} submitting={submitting}>Nächste Schlüsselbestellung</Button>
                </div>
            </>
            }

        </Layout>
    );
}