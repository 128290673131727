import {Redirect, Route} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {showError} from "../../js/main";

export const RouteType = {
    ADMIN: "admin",
    USER: "user"
};

export class RoutingNotifier {

    constructor() {
        this.notified = false;
        this.allowedRoutes = undefined;
        this.listeners = [];
    }

    attachListener(listenerFunction) {
        if (!this.notified) {
            // not yet decided, remember for later
            this.listeners.push(listenerFunction);
        } else {
            // already decided, notify immediately
            listenerFunction(this.allowedRoutes);
        }
    }

    notifyRouting(allowedRoutes) {
        this.listeners.forEach(e => e(allowedRoutes));
        this.allowedRoutes = allowedRoutes;
        this.notified = true;
    }

}

export const ConditionalRoute = ({routingNotifier, type, ...rest}) => {

    const [renderRoute, setRenderRoute] = useState(false);
    const [renderRedirect, setRenderRedirect] = useState(false);

    const listener = (allowedRoutes) => {
        if (allowedRoutes.includes(type)) {
            setRenderRoute(true);
        } else {
            showError("Sie haben keine Berechtigung diese Seite aufzurufen.");
            setRenderRedirect(true);
        }
    };

    useEffect(() => {
        routingNotifier.attachListener(listener)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            {renderRoute && <Route {...rest}/>}
            {renderRedirect && <Redirect to="/"/>}
        </Fragment>
    );

}