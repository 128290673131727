import {Edit} from "./Edit";
import {patch, post, put} from "../../js/api";
import {markDirty, showMessage} from "../../js/main";
import {useHistory, useParams} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {corsFetch, performDownloadS3} from "../../js/util";
import {getOwnUser} from "../../controller/Profile";


export const uploadFile = async (locksystemId, file) => {
    if(file){
        const resp = await post("/locksystems/" + locksystemId +"/generateFileUpload", {"fileType":file.type});
        await corsFetch(resp.uploadUrl, {
            method: "PUT",
            body: file,
            headers:{
                "Content-Disposition": "attachment; filename*= UTF-8''"+file.name
            }
        });
    }
}

export const patchLocksystemKeys = async (locksystemId, locksystemKeysPatchRequest) => {
    if(locksystemKeysPatchRequest && locksystemKeysPatchRequest.updated && locksystemKeysPatchRequest.updated.length > 0){
        let user = await getOwnUser();
        if(user.fileUri){
            try {
                let userImgBase64 = await corsFetch(user.fileUri).then(res => res.blob()).then(blob => {
                    return new Promise((resolve, _) => {
                        let reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.readAsDataURL(blob);
                    });
                });
                locksystemKeysPatchRequest["logoBase64"] = userImgBase64;
            }catch(e){
                console.error("Failed to fetch: ", e);
            }
        }
        let resp = await patch("/locksystems/"+locksystemId+"/keys", locksystemKeysPatchRequest, true);
        if(resp.s3IssueDocumentsUri && resp.s3IssueDocumentsUri.length > 0){
            for(const uri of resp.s3IssueDocumentsUri){
                await performDownloadS3(uri);
            }
            showMessage("Die angeforderten Dokumente wurden heruntergeladen. Falls sie sich nicht automatisch geöffnet haben, kontrollieren Sie bitte Ihren Downloads-Ordner.");
        }
    }
}

export const UpdateLocksystem = ({locksystems, submitting, setSubmitting}) => {

    const history = useHistory();
    const {locksystemId} = useParams();

    const [selectedSystem, setSelectedSystem] = useState();

    useEffect(() => {
        if(locksystems){
            setSelectedSystem(locksystems.find(e => e.id === parseInt(locksystemId)));
        }
    }, [locksystems]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSave = (locksystem, file, locksystemKeysPatch) => {
        put("/locksystems/" + locksystem.id, locksystem).then(async() => {
            await uploadFile(selectedSystem.id, file);
        }).then(async () => await patchLocksystemKeys(selectedSystem.id, locksystemKeysPatch)).then(() => {
            markDirty();
            showMessage("Gespeichert");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Fragment>
            {selectedSystem &&
              <Edit initialSystemInfo={selectedSystem} saveAction={handleSave} submitting={submitting} setSubmitting={setSubmitting}/>}
        </Fragment>
    )
}