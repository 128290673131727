import {CreateNewAction} from "../../component/CreateNewAction";
import {useHistory} from "react-router";
import {DataTable} from "../../component/datatable/DataTable";
import {formatPrice} from "../../js/util";
import {BreadCrumb} from "../../component/Breadcrumb";

export const Overview = ({keys}) => {

    const columns = [
        {
            name: "Artikelnummer",
            selector: (row) => row.itemNo,
            sortable: true,
        },
        {
            name: "Bezeichnung",
            selector: (row) => row.title,
            sortable: true,
        },
        {
            name: "Notiz",
            selector: (row) => row.note,
            sortable: true,
        },
        {
            name: "Preis (netto)",
            selector: (row) => formatPrice(row.price),
            right: true,
            sortable: true,
            sortFunction: (first, second) => first.price - second.price,
            maxWidth: '200px',
        },
    ];

    const history = useHistory();

    const handleRowClick = (row) => {
        history.push("/schluessel/edit/" + row.id);
    };

    return (
        <div>
            <BreadCrumb currentTitle="Schlüssel"/>

            <DataTable data={keys}
                       columns={columns}
                       actions={<CreateNewAction buttonText="Neuen Schlüssel erstellen" redirect="/schluessel/neu"/>}
                       selectableRows
                       batchEditUrl="/schluessel/batch"
                       deleteApi="/keys/"
                       url="/schluessel"
                       preventDeletingAll
                       onRowClicked={handleRowClick}/>
        </div>
    );

};