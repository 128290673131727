import {forwardRef, useEffect, useState} from "react";
import {ReactComponent as CheckboxOn} from "../../img/icon/checkbox-on.svg";
import {ReactComponent as CheckboxIntermediate} from "../../img/icon/checkbox-intermediate.svg";

export const DataTableSelectComponent = forwardRef(({onClick, isIntermediate, ...rest}, ref) => {

    const [isChecked, setChecked] = useState(rest.checked);

    const [focusedClass, setFocusedClass] = useState("");

    useEffect(() => {
        setChecked(rest.checked);
    }, [rest.checked]);

    return (
        <div className="relative top-3">
            <div className={focusedClass + ' checkbox-replacer '}
                 onClick={onClick}>

                {isChecked && <CheckboxOn style={{'width': '90%'}}/>}
                {isIntermediate && rest.name === 'select-all-rows' && <CheckboxIntermediate style={{'width': '70%'}}/>}
            </div>

            <input type="checkbox"
                   ref={ref}
                   className="no-opacity"
                   onFocus={() => setFocusedClass("focused")}
                   onBlur={() => setFocusedClass("")}
                   onInput={onClick}
                   {...rest}/>
        </div>
    );

});