import {BreadCrumb} from "../../component/Breadcrumb";

export const Datenschutz = () => {

    return (
        <div>
            <BreadCrumb currentTitle="Datenschutzvereinbarung"/>
            <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher
                ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
                Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im
                Rahmen unserer Website.</p>

            <h2>Ihre Rechte</h2>
            <p>
                Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung,
                Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten
                gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise
                verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die
                Datenschutzbehörde.
            </p>
            <h2>Kontaktmöglichkeiten</h2>
            <p>Wenn Sie via Telefon oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre Daten zwecks Bearbeitung der
                Anfrage und für den Fall von weiteren Fragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
                Einwilligung weiter.</p>
            <h2>Serverlogfiles</h2>
            <p>
                Aus technischen Gründen werden Informationen an uns bzw. den Webspace-Provider vom Browser übertragen.
                Diese Informationen können folgende Daten umfassen:
            </p>
            <ul>
                <li>Browser inkl. Version</li>
                <li>Betriebssystem</li>
                <li>besuchte Pfade auf unserer Seite</li>
                <li>Datum und Uhrzeit Ihres Zugriffs</li>
                <li>IP-Adresse</li>
            </ul>
            <p>Diese Daten können, falls Sie eingeloggt sind, auch mit Ihrem Benutzer verknüpft werden. Dies dient vor
                allem zur zur Protokollierung des Abruf-Ablaufs und zur Nachvollziehbarkeit bei Fehlern und Nachfragen
                durch Sie selbst. Diese Daten werden nicht weitergegeben und sind nur durch unsere IT-Abteilung bei
                Bedarf abrufbar.</p>
            <h2>Cookies & Deaktivierung</h2>
            <p>Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien,
                die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.
                Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem
                Endgerät gespeichert,
                bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen. In
                allen Browsern können
                Sie Cookies deaktivieren. Entsprechende Anleitungen zu den einzelnen Browsern finden Sie beim jeweiligen
                Hersteller. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.
                <strong>Unsere Website verwendet nur die für die Funktionalität notwendigen Cookies.</strong>
            </p>
            <h2>Informationen zur Online-Streitbeilegung</h2>
            <p>
                Die EU-Kommission bietet die Möglichkeit zur Online-Streitbeilegung auf einer von ihr betriebenen Plattform (sog. „OS-Plattform“). Die OS-Plattform kann als Anlaufstelle zur außergerichtlichen Beilegung von Streitigkeiten aus Online-Kaufverträgen oder Dienstleistungsverträgen dienen. Diese Plattform ist über diesen Link zu erreichen.
            </p>
            <h2>Kontakt für den Datenschutz</h2>
            <p>
                <strong>Lintner Sicherheitstechnik GmbH</strong><br/>
                Innsbruckerstraße 49<br/>
                A-6130 Schwaz<br/>
                <a title="Anrufen" href="tel:+43524262259">+435242/622 59</a><br/>
                <a title="E-Mail senden" href="mailto:sicherheit@lintner.at">sicherheit@lintner.at</a>
            </p>
            <h2>Aktualisierung dieser Datenschutzbestimmungen</h2>
            <p>
                Wir aktualisieren diese Datenschutzbestimmungen regelmäßig. Sollten Sie Fragen zu unseren Datenschutzbestimmungen haben, wenden Sie sich bitte an den unten angeführten Kontakt.
            </p>
        </div>
    );

};