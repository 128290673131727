import {InputText} from "./form/InputText";
import {InputType} from "./form/InputType";
import {SelectOne} from "./form/SelectOne";
import {Fragment, useEffect, useState} from "react";
import {InputCheckbox} from "./form/InputCheckbox";
import {InputTextarea} from "./form/InputTextarea";
import {getGlobal, globalKeys} from "../js/main";
import {genderOptions, groupOptions, rightOptions} from "../js/constants";
import {SelectReact} from "./form/SelectReact";

export const UserInput = ({initialUserInfo, setUserFn}) => {

    const [userInfo, setUserInfo] = useState(initialUserInfo);

    const handleUpdate = (newValue, field) => {
        const updated = Object.fromEntries(Object.entries(userInfo));
        updated[field] = newValue;
        setUserInfo(updated);
        setUserFn(updated);
    };

    useEffect(() => {
        setUserInfo(initialUserInfo);
    }, [initialUserInfo]);


    return (
        <div className="default">
            <InputText name="username" value={userInfo.username} label="Username" setter={handleUpdate} required disabled={initialUserInfo.id !== ""}/>
            <InputType name="email" value={userInfo.email} label="E-Mail" type="email" setter={handleUpdate} required/>
            <InputText name="firstName" value={userInfo.firstName} label="Vorname" setter={handleUpdate} required/>
            <InputText name="lastName" value={userInfo.lastName} label="Nachname" setter={handleUpdate} required/>
            <SelectReact name="gender" label="Geschlecht" value={userInfo.gender}
                         required options={genderOptions} setter={handleUpdate}/>
            <InputText name="company" value={userInfo.company} label="Firma" setter={handleUpdate}/>
            <InputText name="companyAdditional" value={userInfo.companyAdditional} label="Firma Zusatz" setter={handleUpdate}/>
            <InputText name="street" value={userInfo.street} label="Straße" setter={handleUpdate}/>
            <InputText name="postcode" value={userInfo.postcode} label="PLZ" setter={handleUpdate}/>
            <InputText name="city" value={userInfo.city} label="Stadt" setter={handleUpdate}/>
            <InputText name="phoneNumber" value={userInfo.phoneNumber} label="Telefonnummer" setter={handleUpdate}/>
            {getGlobal(globalKeys.isAdmin) &&
             <Fragment>
                 <SelectReact name="userGroup" label="Benutzergruppe" value={userInfo.userGroup}
                              required options={groupOptions} setter={handleUpdate}/>
                 <InputCheckbox name="activated" value={userInfo.activated} label="Aktiviert" style2 big setter={handleUpdate} />
                 <InputTextarea name="note" value={userInfo.note} label="Notiz" setter={handleUpdate}/>
                 <SelectReact name="rights" label="Zusatzberechtigungen" value={userInfo.rights}
                              options={rightOptions} setter={handleUpdate} isMulti/>
            </Fragment>}
        </div>)
}