export const OrderAddressDisplay = ({address}) => {

    return (
        <table>
            <tbody>
                <tr>
                    <td width="120">Name</td>
                    <td>{address.firstName} {address.lastName}</td>
                </tr>
                <tr>
                    <td>Firma</td>
                    <td>{address.company}</td>
                </tr>
                {address.companyAdditional &&
                 <tr>
                    <td>Firma Zusatz</td>
                    <td>{address.companyAdditional}</td>
                </tr>}
                <tr>
                    <td>Straße</td>
                    <td>{address.street}</td>
                </tr>
                <tr>
                    <td>PLZ</td>
                    <td>{address.postcode}</td>
                </tr>
                <tr>
                    <td>Stadt</td>
                    <td>{address.city}</td>
                </tr>
                <tr>
                    <td>Telefon</td>
                    <td>{address.phoneNumber}</td>
                </tr>
                {address.email &&
                 <tr>
                    <td>E-Mail</td>
                    <td>{address.email}</td>
                </tr>}
            </tbody>
        </table>
    );

};