import {useEffect} from "react";
import {setSiteTitle} from "../../js/main";

export const NotFound = () => {

    useEffect(() => {
        setSiteTitle("404");
    }, []);

    return (
        <div>
            <h1>Dieser Schlüssel passt leider in kein Schloss ...</h1>

            <p>Die Seite konnte leider nicht gefunden werden.</p>
        </div>
    );

}