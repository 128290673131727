import React from 'react';
import {Link} from "react-router-dom";

export const Kachel = (props) => {

    return (
        <Link to={props.link} className="kachel">
            <div>
                <div className="icon">
                    {props.children}
                </div>
                <div className="name bold">
                    {props.name}
                </div>
            </div>
        </Link>
    );

};