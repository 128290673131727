import {get} from "../../js/api";
import {Fragment, useEffect, useState} from "react";
import {DataTable} from "../../component/datatable/DataTable";
import {germanDateTime} from "../../js/util";

export const LocksystemEvents = ({locksystemId}) => {

    const [events, setEvents] = useState([]);

    useEffect(() => {
        if(locksystemId){
            get("/locksystems/"+locksystemId+"/events").then((resp) => {
                setEvents(resp);
            });
        }
    }, [locksystemId])

    const columns = [
        {
            name: "Zeitpunkt",
            selector: (row) => germanDateTime(row.timestamp),
            sortable: true,
            maxWidth: '200px',
        },
        {
            name: "Titel",
            selector: (row) => row.title,
            sortable: true,
            maxWidth: '250px',
        },
        {
            name: "Beschreibung",
            selector: (row) => row.details,
            sortable: true,
        },
        {
            name: "Benutzer",
            selector: (row) => row.username,
            sortable: true,
            maxWidth: '200px',
        }
    ];

    return (
        <Fragment>
            {events &&<Fragment>
                <h2>Journal</h2>
                <div className="data-table">
                    <DataTable data={events}
                               columns={columns}
                               nameField="assetNumber"
                               preventDeletingAll/>
                </div>
            </Fragment>}
        </Fragment>
    )
}