import {Layout} from "./Layout";
import {AmplifyAuthContainer, AmplifyAuthenticator, AmplifySignIn} from "@aws-amplify/ui-react";
import {useEffect} from "react";
import {onAuthUIStateChange} from "@aws-amplify/ui-components";
import {NavLink} from "react-router-dom";

export const Login = () => {

    const stateUpdate = (state) => {
        switch (state) {
            case "signin":
                injectStylesSignIn();
                break;
            case "forgotpassword":
                injectStylesForgotPassword();
                break;
            case "verifyContact":
                injectStylesVerifyContact();
                break;
            default:
                break;
        }
    };

    const injectStylesSignIn = () => {
        try {
            const amplifySignIn = document.querySelector("amplify-sign-in");
            const styleElement = document.createElement("style");
            styleElement.innerHTML = "@import url('/login.css');"
            amplifySignIn.shadowRoot.children[0].insertAdjacentElement("beforebegin", styleElement)
        } catch (e) {
            setTimeout(injectStylesSignIn, 10);
        }
    };

    const injectStylesForgotPassword = () => {
        try {
            const amplifyForgotPassword = document.querySelector("amplify-authenticator").shadowRoot
                                                  .querySelector("amplify-forgot-password");
            const styleElement = document.createElement("style");
            styleElement.innerHTML = "@import url('/login.css');"
            amplifyForgotPassword.shadowRoot.children[0].insertAdjacentElement("beforebegin", styleElement);
        } catch (e) {
            setTimeout(injectStylesForgotPassword, 10);
        }
    };

    const injectStylesVerifyContact = () => {
        try {
            const amplifyVerifyContact = document.querySelector("amplify-authenticator").shadowRoot
                                                  .querySelector("amplify-verify-contact");
            const styleElement = document.createElement("style");
            styleElement.innerHTML = "@import url('/login.css');"
            amplifyVerifyContact.shadowRoot.children[0].insertAdjacentElement("beforebegin", styleElement);
            amplifyVerifyContact.shadowRoot.querySelector("[data-test='verify-contact-skip-link'] button").innerHTML = "Überspringen";
        } catch (e) {
            setTimeout(injectStylesVerifyContact, 10);
        }
    };

    const injectStylesForToast = (i) => {
        if (i === 9) {
            return;
        }
        try {
            const amplifyToast = document.querySelector("amplify-authenticator").shadowRoot
                                         .querySelector("amplify-toast");
            const styleElement = document.createElement("style");
            styleElement.innerHTML = "@import url('/login.css');"
            amplifyToast.shadowRoot.children[0].insertAdjacentElement("beforebegin", styleElement);
        } catch (e) {
            setTimeout(() => injectStylesForToast(++i), 10);
        }
    };

    const observeLogin = () => {
        // Select the node that will be observed for mutations
        const targetNode = document.querySelector("amplify-authenticator").shadowRoot;

        // Options for the observer (which mutations to observe)
        const config = {attributes: false, childList: true};

        // Callback function to execute when mutations are observed
        const callback = function(mutationsList) {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    injectStylesForToast(0);
                }
            }
        };
        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);

        // Start observing the target node for configured mutations
        observer.observe(targetNode, config);
    };

    useEffect(() => {
        onAuthUIStateChange(stateUpdate);
        observeLogin();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Layout username={null}>
            <div className="default">
                <div className="login">
                    <AmplifyAuthContainer>
                        <AmplifyAuthenticator>
                            <AmplifySignIn headerText="Login" slot="sign-in" hideSignUp/>
                        </AmplifyAuthenticator>
                    </AmplifyAuthContainer>
                </div>
                <div className="guest-request-tile">
                    <div className="guest-request-content">
                            <div className="guest-request-form-header">
                                <h3 className="header">Schlüssel nachmachen</h3>
                            </div>
                            <div className="guest-request-description mb-2">
                                <p>Hier können Sie als nicht registrierter Kunde eine Schlüsselbestellung aufgeben.<br/><br/>
                                    Bestellen Sie schnell und unkompliziert einen Ersatzschlüssel. Dazu müssen Sie einfach nur zwei Fotos machen, um den Rest kümmern wir uns.
                                </p>
                            </div>
                            <div className="guest-request-footer">
                                <NavLink to="guest-request"><button type="submit" className={"primary-button"}>Schlüssel bestellen</button></NavLink>
                    </div>
                    </div>
                </div>
            </div>
        </Layout>
    );

};

