import {useHistory, useParams} from "react-router-dom";
import {Edit} from "./Edit";
import {put} from "../../js/api";
import {markDirty, showMessage} from "../../js/main";
import {Fragment, useEffect, useState} from "react";

export const UpdateKey = ({keys}) => {

    const history = useHistory();
    const {keyId} = useParams();

    const [selectedKey, setSelectedKey] = useState();

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (keys) {
            setSelectedKey(keys.find(e => e.id === parseInt(keyId)));
        }
    }, [keys]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSave = (key) => {
        put("/keys/" + key.id, key).then(() => {
            markDirty();
            showMessage("Gespeichert.");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Fragment>
            {selectedKey &&
             <Edit initialKeyInfo={selectedKey} saveAction={handleSave} submitting={submitting} setSubmitting={setSubmitting}/>}
        </Fragment>
    )
}