import {getOrderStateTag, getOrderStateText} from "../myOrders/Overview";
import {DataTable} from "../../component/datatable/DataTable";
import {formatPrice, germanDateTime, sumItemsDouble} from "../../js/util";
import {SelectOne} from "../../component/form/SelectOne";
import {yearsOptions} from "../../js/constants";
import {BreadCrumb} from "../../component/Breadcrumb";
import {OrderItems} from "../../component/OrderItems";
import {SelectReact} from "../../component/form/SelectReact";

export const Overview = ({orders, selectedYear, setSelectedYear}) => {

    const columns = [
        {
            name: "Bestellnummer",
            selector: (row) => row.id,
            maxWidth: '200px',
            sortable: true,
        },
        {
            name: "Datum",
            selector: (row) => germanDateTime(row.creationDate),
            sortable: true,
            maxWidth: '200px',
            sortFunction: (first, second) => first.creationDate - second.creationDate,
        },
        {
            name: "Username",
            selector: (row) => row.username,
            maxWidth: '200px',
            sortable: true,
        },
        {
            name: "Firma",
            selector: (row) =>  row.billingAddress?.company,
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => getOrderStateTag(row.state),
            maxWidth: '200px',
            sortFunction: (first, second) => getOrderStateText(first.state).localeCompare(getOrderStateText(second.state)),
            sortable: true,
        },
        {
            name: "Summe (netto)",
            selector: (row) => formatPrice(row.sum),
            right: true,
            sortable: true,
            maxWidth: '200px',
            sortFunction: (first, second) => sumItemsDouble(first.items) - sumItemsDouble(second.items),
        },
    ];


    return (
        <div>
            <BreadCrumb currentTitle="Bestellungen"/>

            <DataTable data={orders}
                       columns={columns}
                       expandableRows
                       expandOnRowClicked
                       expandableRowsComponent={<OrderItems inline/>}
                       paginationPerPage={25}
                       actions={<SelectReact value={selectedYear} setter={setSelectedYear} options={yearsOptions}/>}/>
        </div>
    );

};