import {CreateNewAction} from "../../component/CreateNewAction";
import {useHistory} from "react-router-dom";
import {DataTable} from "../../component/datatable/DataTable";
import {ReactComponent as CheckboxOn} from "../../img/icon/checkbox-on.svg";
import {ReactComponent as CheckboxOff} from "../../img/icon/checkbox-off.svg";
import {BreadCrumb} from "../../component/Breadcrumb";

export const Overview = ({users}) => {

    const columns = [
        {
            name: "Benutzername",
            selector: (row) => row.username,
            sortable: true,
            maxWidth: '250px',
        },
        {
            name: "E-Mail",
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: "Firma",
            selector: (row) => row.company,
            sortable: true,
        },
        {
            name: "Benutzergruppe",
            selector: (row) => (row.userGroup && 'Administrator') || 'User',
            sortable: true,
            maxWidth: '230px',
        },
        {
            name: "Aktiv",
            selector: (row) =>
                <>
                    {row.activated && <CheckboxOn style={{'width': '32px'}}/>}
                    {!row.activated && <CheckboxOff style={{'width': '27px'}}/>}
                </>,
            sortable: true,
            sortFunction: (first, second) => first.activated - second.activated,
            maxWidth: '100px',
        },
    ];

    const history = useHistory();

    const handleRowClick = (row) => {
        history.push("/benutzer/edit/" + encodeURIComponent(row.username));
    };

    return (
        <div>
            <BreadCrumb currentTitle="Benutzer"/>

            <div className="data-table">
                <DataTable keyField="username"
                           data={users}
                           columns={columns}
                           actions={<CreateNewAction buttonText="Neuen Benutzer anlegen" redirect="/benutzer/neu"/>}
                           onRowClicked={handleRowClick}/>
            </div>
        </div>
    );

}