import {Fragment} from "react";
import {PriceDisplay} from "../controller/CreateOrder";
import {DataTable} from "./datatable/DataTable";
import {formatPrice} from "../js/util";
import {useHistory} from "react-router";
import {ReactComponent as ForwardButton} from "../img/icon/back-arrow.svg";
import {getGlobal, globalKeys} from "../js/main";
import {get} from "../js/api";

export const OrderItems = ({data, inline = false}) => {

    const extractFileName = (s3Uri) =>{
        return decodeURI(s3Uri.substring(s3Uri.lastIndexOf("/")+1, s3Uri.length));
    }

    const downloadAttachment = (attachment) =>{
        get("/s3/adminDownload?s3identifier="+encodeURI(attachment.s3identifier)).then(res => {
            let frame = document.createElement("iframe");
            frame.src = res.downloadUrl;
            frame.style.display = "none";
            document.body.append(frame);
        });
    }

    const itemColumns = [
        {
            name: "Artikel",
            cell: (row) => <div>
                <h3 className="mt-05 mb-03">{row.title}</h3>
                <div className="smaller">
                    {row.properties.map(prop => {
                        return prop.value &&
                               <Fragment key={prop.key}>
                                   <span className={prop.key == "Systemmeldung" ? 'red' : ''}>{prop.key}: <strong>{prop.value}</strong></span>
                                   <br className="mb-03"/>
                               </Fragment>
                    })}
                    {getGlobal(globalKeys.isAdmin) && row.attachments.length > 0 && <Fragment>
                        <span><strong>Anhänge: </strong>{row.attachments.map(att => {
                            return <a className="attachment" onClick={(e) => {e.preventDefault();downloadAttachment(att)}} href="#">{extractFileName(att.s3identifier)}</a>
                        })}</span>
                    </Fragment>}
                    <br/>
                </div>
            </div>
        },
        {
            name: "Anzahl",
            maxWidth: '150px',
            right: true,
            selector: (row) => row.amount
        },
        {
            name: "Stückpreis",
            maxWidth: '200px',
            right: true,
            selector: (row) => formatPrice(row.price),
        },
        {
            name: "Preis (netto)",
            maxWidth: '350px',
            right: true,
            selector: (row) => PriceDisplay(row.price, row.discount, row.amount)
        }
    ];

    const history = useHistory();

    const openOrder = (data) => {
        if (getGlobal(globalKeys.isAdmin)) {
            history.push("/bestellungen/bearbeiten/" + data.id);
        } else {
            history.push("/meine-bestellungen/bearbeiten/" + data.id);
        }
    };

    return (
        <div className="data-table no-hover">
            <br/>
            {inline &&
             <div className="center">
                <button className="forward-button mb-1" onClick={() => openOrder(data)}>
                    <ForwardButton/>
                    Bestelldetails anzeigen
                </button>
            </div>}

            <div className={'mt-0 ' + (inline ? 'mb-2' : 'mb-04')}>
                <DataTable data={data.items}
                           columns={itemColumns}
                           noHeader
                           noSearch
                           noScrollInfo
                           removeTopPadding/>
            </div>
        </div>
    );

}