import {Link} from "react-router-dom";

export const CreateNewAction = ({buttonText, redirect}) => {

    return (
        <Link to={redirect}>
            <button>{buttonText}</button>
        </Link>
    );

};