import {Fragment, useEffect, useState} from "react";
import {BreadCrumb} from "../../component/Breadcrumb";
import {Button} from "../../component/form/Button";
import {useParams} from "react-router-dom";
import {InputText} from "../../component/form/InputText";
import {InputPrice} from "../../component/form/InputPrice";

export const BatchUpdateKey = ({keys, saveAction, submitting, setSubmitting}) => {

    const {keyIds} = useParams();

    const [selectedKeys, setSelectedKeys] = useState([]);

    useEffect(() => {
        if (!keys) {
            return;
        }
        const keyIdsArray = keyIds.split(",").map(Number);
        let filtered = keys.filter(key => keyIdsArray.includes(key.id));
        setSelectedKeys(new Map(filtered.map(key => [parseInt(key.id), key])));
    }, [keys, keyIds]);

    const handleUpdate = (id, newValue, field) => {
        let defensiveCopy = new Map(selectedKeys);
        defensiveCopy.get(id)[field] = newValue;
        setSelectedKeys(defensiveCopy);
    };


    return (
        <div>
            <BreadCrumb currentTitle="Schlüssel bearbeiten"
                        parentTitle="Schlüssel"
                        parentLink="/schluessel"/>

            <form onSubmit={(e) => {
                e.preventDefault();
                saveAction(selectedKeys);
            }}>
                <div className="default side-by-side-3">
                    {Array.from(selectedKeys, v => v[1]).map((key, index) =>
                       <Fragment key={key.id}>
                            <InputText concatId={key.id} name="itemNo" value={key.itemNo} label={index === 0 && "Artikelnummer"} setter={(newValue, field) => handleUpdate(key.id, newValue, field)} />
                            <InputText concatId={key.id} name="title" value={key.title} label={index === 0 && "Bezeichnung"} setter={(newValue, field) => handleUpdate(key.id, newValue, field)} required/>
                            <InputPrice concatId={key.id} name="price" value={key.price} label={index === 0 && "Preis € (netto)"} setter={(newValue, field) => handleUpdate(key.id, newValue, field)} required/>
                        </Fragment>
                    )}
                </div>

                <Button submitting={submitting}>speichern</Button>
            </form>
        </div>
    );

};