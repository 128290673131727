import {get} from "../../js/api";
import {Fragment, useEffect, useState} from "react";
import {SelectOne} from "../../component/form/SelectOne";
import {InputText} from "../../component/form/InputText";
import {InputTextarea} from "../../component/form/InputTextarea";
import {InputPrice} from "../../component/form/InputPrice";
import {InputType} from "../../component/form/InputType";
import {BreadCrumb} from "../../component/Breadcrumb";
import {applyDiscount} from "../../js/util";
import {Button} from "../../component/form/Button";
import {InputFile} from "../../component/form/InputFile";
import {ReactComponent as IconFile} from "../../img/icon/download.svg";
import {ReactComponent as IconFile2} from "../../img/icon/externallink.svg";
import {LocksystemKeyEdit} from "./LocksystemKeyEdit";
import {LocksystemEvents} from "../locksystem/LocksystemEvents";
import {SelectReact} from "../../component/form/SelectReact";
import {getOwnUser} from "../../controller/Profile";
import {InputCheckbox} from "../../component/form/InputCheckbox";

export const Edit = ({initialSystemInfo, saveAction, submitting, setSubmitting}) => {

    const [availableKeys, setAvailableKeys] = useState([]);
    const [locksystemInfo, setLocksystemInfo] = useState(initialSystemInfo);
    const [locksystemKeysInfo, setLocksystemKeysInfo] = useState([]);
    const [calculatedPrice, setCalculatedPrice] = useState("0,00");
    const [canUploadPlan, setCanUploadPlan] = useState(false)
    const [canManageKeys, setCanManageKeys] = useState(false)
    const [canLinkPlan, setCanLinkPlan] = useState(false);
    const [printIssueDocument, setPrintIssueDocument] = useState(false);
    //changes
    const [file, setFile] = useState();
    const [locksystemKeysPatch, setLocksystemKeysPatch] = useState();

    useEffect(() => {
        get("/keys").then((resp) => {
            setAvailableKeys(resp);
        });
        getOwnUser().then(usr => {
           setCanUploadPlan(usr.rights.indexOf('LOCKSYSTEM_UPLOAD') > -1);
           setCanLinkPlan(usr.rights.indexOf("LOCKSYSTEM_LINK") > -1);
           setCanManageKeys(usr.rights.indexOf('KEY_MANAGEMENT') > -1);
        });
    }, []);

    const handleUpdate = (newValue, field) => {
        let updatedInfo = Object.fromEntries(Object.entries(locksystemInfo));
        updatedInfo[field] = newValue;
        setLocksystemInfo(updatedInfo);
    };

    useEffect(() => {
        if(locksystemInfo.id && canManageKeys) {
            get("/locksystems/"+locksystemInfo.id+"/keys").then((resp) => {
               setLocksystemKeysInfo(resp);
            });
        }
    }, [locksystemInfo, canManageKeys]);
    const handleFile = (file) => {
        setFile(file);
    }

    useEffect(() => {
        const key = availableKeys.find(key => String(key.id) === String(locksystemInfo.keyId));
        if (key) {
            setCalculatedPrice(applyDiscount(key.price, locksystemInfo.discount));
        }
    }, [availableKeys, locksystemInfo.keyId, locksystemInfo.discount]);

    const keyOptions = [];
    availableKeys.forEach(e => {
        keyOptions.push({value:e.id, label:e.title});
    });

    return (
        <div>
            <BreadCrumb currentTitle={'Schließanlage ' + (locksystemInfo.id === null ? 'hinzufügen' : 'bearbeiten')}
                        parentTitle="Meine Schließanlagen"
                        parentLink="/meine-schliessanlagen"/>

            <form onSubmit={e => {
                e.preventDefault();
                setSubmitting(true);
                const locksystemKeysPatchRequest = {
                    createIssueDocument: printIssueDocument,
                    updated: locksystemKeysPatch
                }
                saveAction(locksystemInfo, file, locksystemKeysPatchRequest);
            }}>
                <div className="default">
                    <InputText name="assetNumber"
                               value={locksystemInfo.assetNumber}
                               label="Anlagennummer"
                               disabled={true}/>
                    <InputText name="sys" value={locksystemInfo.sys} label="System" disabled={true}/>
                    <SelectReact name="keyId" label="Schlüssel" value={locksystemInfo.keyId}
                                 options={keyOptions} disabled={true}/>
                    <InputPrice name="calculated" value={calculatedPrice} label="Preis € (netto)" disabled/>
                    {canUploadPlan && <Fragment>
                        <InputFile name="fileUpload" maxMBSize="10" accept="*" label="Schließplan" setter={handleFile}/>
                        {locksystemInfo.fileUri && <a className="file-uri" href={locksystemInfo.fileUri} target="_blank"><IconFile className="file-svg file-svg-center"/><h3 className={"file-svg-center"}>Schließplan herunterladen</h3></a>}
                    </Fragment>}
                </div>
                {canLinkPlan && <div className="default">
                    <InputText name="externalUri" value={locksystemInfo.externalUri} label={"Externer Dokumentlink"}
                               setter={handleUpdate}/>
                    {locksystemInfo.externalUri &&
                        <a className="file-uri" href={locksystemInfo.externalUri} target="_blank"><IconFile2
                            className="file-svg file-svg-center mr-1"/><h3
                            className={"file-svg-center"}>Schließplanlink öffnen</h3></a>}
                </div>}
                {canManageKeys && <Fragment>
                    <hr/>
                    <LocksystemKeyEdit locksystem={locksystemInfo} initialLocksystemKeys={locksystemKeysInfo}
                                       canCreate={false} setPatch={setLocksystemKeysPatch}/>
                </Fragment>}
                <hr style={{'marginBottom': '1.5em'}}/>
                <div className={"safe-actions"}>
                    {locksystemKeysPatch && locksystemKeysPatch.length > 0&& <div className="default"><InputCheckbox label={"Schlüsseldokumente drucken?"} value={printIssueDocument} setter={setPrintIssueDocument} name={"printIssueDocument"}></InputCheckbox></div>}
                    <Button key="back" theme="secondary-button mr-1 mb-1" width={230} submitting={submitting} onClick={e => {e.preventDefault(); window.history.back()}}>zurück</Button>
                    <Button key="submit" theme="primary-button mb-1" width={230} submitting={submitting}>speichern</Button>
                </div>
                <hr/>
                <LocksystemEvents locksystemId={locksystemInfo.id}/>
            </form>
        </div>
    );
};