import {Kachel} from "../../component/Kachel";
import {ReactComponent as IconLock} from "../../img/icon/locksystem.svg";
import {ReactComponent as IconKeys} from "../../img/icon/keys.svg";
import {ReactComponent as IconMan} from "../../img/icon/man.svg";
import {ReactComponent as IconOrder} from "../../img/icon/orders.svg";
import {ReactComponent as IconShippingOrder} from "../../img/icon/shipping.svg";
import {BreadCrumb} from "../../component/Breadcrumb";
import {useEffect, useState} from "react";
import {getOwnUser} from "../../controller/Profile";

export const HomeAdmin = () => {

    const [welcomeMsg, setWelcomeMsg] = useState("Willkommen!");
    const [imgPath, setImgPath] = useState();
    useEffect(() => {
        getOwnUser().then( u => {
            setWelcomeMsg("Willkommen, "+u.firstName+" "+u.lastName+"!");
            setImgPath(u.fileUri);
        });
    }, []);

    return (
        <div>
            <div className="default welcome-breadcrumb">
                <BreadCrumb currentTitle={welcomeMsg}/>

                {imgPath && <img name="user-img" src={imgPath} />}
            </div>
            <div className="kacheln">
                <Kachel name="Bestellungen" link="/bestellungen">
                    <IconOrder/>
                </Kachel>

                <Kachel name="Benutzer" link="/benutzer">
                    <IconMan/>
                </Kachel>

                <Kachel name="Schließanlagen" link="/schliessanlagen">
                    <IconLock/>
                </Kachel>

                <Kachel name="Schlüssel" link="/schluessel">
                    <IconKeys/>
                </Kachel>

                <Kachel name="Versand" link="/versand">
                    <IconShippingOrder/>
                </Kachel>
            </div>
        </div>
    );

};