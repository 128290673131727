import {BreadCrumb} from "../../component/Breadcrumb";

export const Impressum = () => {

    return (
        <div>
            <BreadCrumb currentTitle="Impressum"/>

            <h3>Lintner Sicherheitstechnik GmbH</h3>

            <p>
                Innsbrucker Straße 49<br/>
                6130 Schwaz
            </p>

            <p>
                Tel.: +43/5242/62259<br/>
                Fax: +43/5242/62259-10<br/>
                sicherheit@lintner.at
            </p>

            <p>
                UID-Nr.: ATU66958429<br/>
                Firmenbuch: 374799a<br/>
                Gerichtsstand: BG Schwaz<br/>
                Unternehmensgegenstand: Handel, Beratung, Verkauf und Vertrieb von Türen, Tore, Sicherheitstechnik, Alarmanlagen, Schliessanlagen, Videoüberwachung, Fassaden sowie Metallbau<br/><br/>
            </p>

            <h3>Webdesign & technische Umsetzung</h3>

            <p>
                <a href="https://inndevs.com">inndevs.com</a><br/>
                <a href="https://www.ddc-engineering.at">ddc-engineering.at</a>
            </p>
        </div>
    );

};