import {NavLink} from "react-router-dom";
import {Fragment} from "react";

export const NavUser = () => {

    return (
        <Fragment>
            <NavLink to="/neue-bestellung" activeClassName="current">Schlüssel bestellen</NavLink>
            <NavLink to="/meine-bestellungen" activeClassName="current">Meine Bestellungen</NavLink>
            <NavLink to="/meine-schliessanlagen" activeClassName="current">Meine Schließanlagen</NavLink>
        </Fragment>
    );

}