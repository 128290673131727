import {useRef, useState} from "react";
import {ReactComponent as CheckboxOn} from "../../img/icon/checkbox-on.svg";
import {ReactComponent as CheckboxOff} from "../../img/icon/checkbox-off.svg";

export const InputCheckbox = ({
                                  name,
                                  value,
                                  label,
                                  setter,
                                  style2 = false, // style 2 = unchecked is big fat red X
                                  required = false,
                                  disabled = false,
                                  concatId = null,
                                  big = false
                              }) => {

    const identifier = concatId ? name + concatId : name;

    const [focusedClass, setFocusedClass] = useState("");
    const checkboxInputRef = useRef();

    const handleChange = (newValue) => {
        setter(newValue, name);
        checkboxInputRef.current.focus(); // if you click on a checkbox and want to navigate to the next one with tab after that
    };

    return (
        <div>
            {label &&
             <label htmlFor={identifier}>
                 {label} {required && <span className="primary-link bold">*</span>}
             </label>}

            <div className={focusedClass + ' checkbox-replacer ' + (big ? 'big' : '')}
                 onClick={() => {handleChange(!value)}}>

                {value && <CheckboxOn style={{'width': '90%'}}/>}
                {!value && style2 && <CheckboxOff style={{'width': '85%'}}/>}
            </div>

            <input type="checkbox"
                   ref={checkboxInputRef}
                   className="no-opacity"
                   id={identifier}
                   checked={value}
                   onFocus={() => setFocusedClass("focused")}
                   onBlur={() => setFocusedClass("")}
                   onChange={(e) => handleChange(e.target.checked)} // enable tabbing + space bar to the actual checbox, for accessibility
                   disabled={disabled}
                   required={required}/>
        </div>
    );

};