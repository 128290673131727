/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:48658279-c937-4efe-a991-42f2d4762624",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_CDcCun5EL",
    "aws_user_pools_web_client_id": "6v45lkogtdmeekoj78vejtgnu0",
    "oauth": {}
};


export default awsmobile;
