import {DataTable} from "./datatable/DataTable";
import {formatPrice, sumItemsDouble} from "../js/util";
import {Fragment} from "react";

export const OrderTotal = ({orderItems, shipping}) => {
    const calcSum = () => {
        let sum = sumItemsDouble(orderItems);
        if(shipping){
            sum += shipping.price;
        }
        return sum;
    }

    const calcUst = ()=> {
        return calcSum()*0.2;
    }

    const calcGrossSum = () => {
        return calcUst()+calcSum();
    }

    return (
        <Fragment>
            <div className="data-table mt-0 no-hover inline-block" style={{ width: '400px', maxWidth: '100%'}}>
                <DataTable data={[{}]}
                           columns={[
                               {
                                   name: "Gesamtbetrag netto",
                                   right: true,
                                   selector: (row) => <strong>{formatPrice(calcSum())}</strong>,
                               }
                           ]}
                           noHeader
                           noSearch
                           removeTopPadding/>
            </div>
            <br/>
            <div className="data-table mt-0 no-hover inline-block" style={{ width: '400px', maxWidth: '100%'}}>
                <DataTable data={[{}]}
                           columns={[
                               {
                                   name: "Ust. 20%",
                                   right: true,
                                   selector: (row) => <strong>{formatPrice(calcUst())}</strong>,
                               }
                           ]}
                           noHeader
                           noSearch
                           removeTopPadding/>
            </div>
            <br/>
            <div className="data-table mt-0 no-hover inline-block" style={{ width: '400px', maxWidth: '100%'}}>
                <DataTable data={[{}]}
                           columns={[
                               {
                                   name: "Gesamtpreis brutto",
                                   right: true,
                                   selector: (row) => <strong>{formatPrice(calcGrossSum())}</strong>,
                               }
                           ]}
                           noHeader
                           noSearch
                           removeTopPadding/>
            </div>
        </Fragment>
    );

};