import {SelectOne} from "../component/form/SelectOne";
import Select from 'react-select'
import {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {get, post} from "../js/api";
import {InputText} from "../component/form/InputText";
import {InputType} from "../component/form/InputType";
import {InputTextarea} from "../component/form/InputTextarea";
import {markDirty, showError, showMessage} from "../js/main";
import {AddressInput, empty as emptyAddress} from "../component/form/AddressInput";
import {getOwnUser} from "./Profile";
import {useHistory} from "react-router-dom";
import {DataTable} from "../component/datatable/DataTable";
import {applyDiscount, formatPrice, SuccessWrapper, sumItems, sumItemsDouble} from "../js/util";
import {Button} from "../component/form/Button";
import {Auth} from "aws-amplify";
import {BreadCrumb} from "../component/Breadcrumb";
import {InputCheckbox} from "../component/form/InputCheckbox";
import {SelectReact} from "../component/form/SelectReact";
import {OrderTotal} from "../component/OrderTotal";
import {InputFile} from "../component/form/InputFile";
import {orderType, paymentOptions} from "../js/constants";

export const PriceDisplay = (price, discount, amount) => {
    const calculatedPrice = applyDiscount(price, discount) * amount;

    let msg = formatPrice(calculatedPrice);
    if (discount !== 0 && calculatedPrice !== 0.0) {
        msg += " (inkl. " + discount.toFixed(discount % 1 === 0 ? 0 : 2) + " % Rabatt)";
    }
    return msg;
};

export const CreateOrder = () => {

    const empty = {
        locksystemId: "",
        keyNumber: "",
        amount: 1,
        note: "",
        calculatedPrice: 0,
        frontImage: null,
        backImage: null
    };

    const history = useHistory();
    // settings and responses from services
    const [authorizationEnabled, setAuthorizationEnabled] = useState(false);
    const [invoicingEnabled, setInvoicingEnabled] = useState(false);
    const [usrImgBase64, setUsrImgBase64] = useState();
    const [ownUser, setOwnUser] = useState();
    const [authorizedSystems, setAuthorizedSystems] = useState([]);
    const [availableKeys, setAvailableKeys] = useState([]);
    const [locksystemOptions, setLocksystemOptions] = useState([]);
    const [locksystemKeyOptions, setLocksystemKeyOptions] = useState([]);
    const [availableShipping, setAvailableShipping] = useState([]);
    const [shippingSelectOptions, setShippingSelectOptions] = useState([]);
    const [isNotPossible, setNotPossible] = useState(false);

    //user selected
    const [authorizationOrder, setAuthorizationOrder] = useState(false);
    const [internalOrderId, setInternalOrderId] = useState();
    const [selectedShipping, setSelectedShipping] = useState(null);
    const [selectedPaymentType, setSelectedPaymentType] = useState(null);
    const [payerEmail, setPayerEmail] = useState(null);
    const [useBillingAddressForShipping, setUseBillingAddressForShipping] = useState(true);
    const [billingAddress, setBillingAddress] = useState(emptyAddress);
    const [shippingAddress, setShippingAddress] = useState(emptyAddress);

    const [submitting, setSubmitting] = useState(false);

    const [orderItems, setOrderItems] = useState([]);
    const [currentOrderItem, setCurrentOrderItem] = useState(empty);

    const frontViewRef = useRef();
    const backViewRef = useRef();

    useEffect(() => {
        Auth.currentSession().then(sess => {
            if (!sess.getIdToken().decodePayload()["email_verified"]) {
                showError("Eine Bestellung kann aus Sicherheitsgründen erst nach erfolgreicher Bestätigung der E-Mail-Adresse und anschließendem erneutem Login durchgeführt werden.");
                setNotPossible(true);
                return;
            }
            get("/keys").then((keys) => setAvailableKeys(keys));
            get("/locksystems").then((systems) => {
                if (systems.length > 0) {
                    setAuthorizedSystems(systems);
                    const options = [];//[e.id, e.sys + " (" + e.assetNumber + ")"]
                    systems.forEach(e => options.push({value: e.id, label: e.sys + " (" + e.assetNumber + ")"}));
                    setLocksystemOptions(options);
                } else {
                    showError("Leider sind keine Schließanlagen berechtigt, daher können Sie keine Bestellung durchführen.");
                    setNotPossible(true);
                }
            });
            get("/shippings").then(shippings => {
                setAvailableShipping(shippings);
                const options = [];
                shippings.forEach(s => options.push({value: s.id, label: s.title + " (" + s.price + " €)"}));
                setShippingSelectOptions(options);
            });
            getOwnUser().then(user => {
                setOwnUser(user);

                let addr = {...shippingAddress};
                for (let k in shippingAddress) {
                    addr[k] = user[k];
                }
                setBillingAddress(addr);
                setAuthorizationEnabled(user.rights.indexOf("AUTHORIZATION") > -1);
                setInvoicingEnabled(user.rights.indexOf("INVOICING") > -1);
                if (user.fileUri) {
                    fetch(user.fileUri, {mode:"cors"}).then(res => res.blob()).then(blob => {
                        return new Promise((resolve, _) => {
                            let reader = new FileReader();
                            reader.onloadend = () => resolve(reader.result);
                            reader.readAsDataURL(blob);
                        });
                    }).then(setUsrImgBase64);
                }
            });
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (ownUser && ownUser.rights.indexOf('KEY_MANAGEMENT') > -1 && currentOrderItem.locksystemId) {
            get("/locksystems/" + currentOrderItem.locksystemId + "/keys").then((locksystemKeys) => {
                const options = []
                locksystemKeys.forEach(k => options.push({value: k.name, label: k.name}));
                setLocksystemKeyOptions(options);
            });
        }
        currentOrderItem.keyNumber = "";
    }, [currentOrderItem.locksystemId, ownUser])

    const handleUpdate = (newValue, field) => {
        const updated = Object.fromEntries(Object.entries(currentOrderItem));
        updated[field] = newValue;
        setCurrentOrderItem(updated);
    };

    const handleFile = (file, name) => {
        handleUpdate(file, name);
    }

    const addKeyNumber = (value) => {
        const data = [...locksystemKeyOptions];
        data.push({value: value, label: value});
        setLocksystemKeyOptions(data)
        handleUpdate(value, "keyNumber");
    }

    const addOrderItem = () => {
        let updated = [...orderItems];
        updated.push(currentOrderItem);
        setOrderItems(updated);
        setCurrentOrderItem(empty);
        frontViewRef.current.value = null;
        backViewRef.current.value = null;
        showMessage("Artikel hinzugefügt, siehe unten.");
    };

    const findLocksystem = (locksystemId) => {
        return authorizedSystems.find(e => e.id === parseInt(locksystemId));
    };


    useEffect(() => { // calculate price
        if (currentOrderItem.locksystemId !== "") {
            const locksystem = findLocksystem(currentOrderItem.locksystemId);
            const key = availableKeys.find(k => k.id === locksystem.keyId)
            let updatedItem = {...currentOrderItem};
            updatedItem.price = locksystem.discountPrice ? locksystem.discountPrice : key.price;
            updatedItem.discount = locksystem.discount;

            updatedItem.calculatedPrice = PriceDisplay(updatedItem.price, updatedItem.discount, updatedItem.amount);

            setCurrentOrderItem(updatedItem);
        }
    }, [currentOrderItem.locksystemId, currentOrderItem.amount]); // eslint-disable-line react-hooks/exhaustive-deps

    const columns = [
        {
            name: "Schließanlage",
            selector: (row) => findLocksystem(row.locksystemId).sys,
        },
        {
            name: "Schlüsselnummer",
            selector: (row) => row.keyNumber,
            maxWidth: '250px',
        },
        {
            name: "Anzahl",
            selector: (row) => row.amount,
            maxWidth: '120px',
        },
        {
            name: "Bemerkung",
            selector: (row) => row.note,
        },
        {
            name: "Preis (netto, zzgl. 20 % USt.)",
            selector: (row) => row.calculatedPrice,
            right: true,
            maxWidth: '300px',
        },
        {
            name: "",
            cell: (row) => {
                return <Button theme="red" onClick={() => {
                    const index = orderItems.indexOf(row);
                    const items = [...orderItems];
                    items.splice(index, 1);
                    setOrderItems(items);
                }} aria-label="Eintrag löschen">X</Button>
            },
            maxWidth: '100px',
        }
    ];

    const uploadImage = async (item, side, file) => {
        const filename = `${findLocksystem(item.locksystemId).sys}_${item.keyNumber}_${side}${file.name.substring(file.name.lastIndexOf("."))}`;
        const resp = await post("/s3/generateUpload", {
            "folder": "orderImages",
            "fileName": filename,
            "fileType": file.type
        });
        await fetch(resp.httpUploadUri, {
            method: "PUT",
            body: file,
            headers: {
                "Content-Disposition": "attachment; filename*= UTF-8''" + filename
            }
        });

        return resp.s3identifier;
    }

    const order = async () => {
        const shipping = availableShipping.find(s => s.id === selectedShipping);

        let requestedItems = [];

        let orderSummaryInfo = {
            locksystems: [],
            keycount: 0
        };
        orderItems.forEach(e => {
            if (!orderSummaryInfo.locksystems.includes(e.locksystemId)) {
                orderSummaryInfo.locksystems.push(e.locksystemId);
            }
            orderSummaryInfo.keycount += parseInt(e.amount);
        });

        const successWrapper = new SuccessWrapper();

        Promise.all(orderItems.map(async item => {
            if (item.frontImage) {
                item.frontImage = await uploadImage(item, 'vorne', item.frontImage);
            }
            if (item.backImage) {
                item.backImage = await uploadImage(item, 'hinten', item.backImage);
            }

            let mapping;
            if (authorizationOrder) {
                const req = {
                    keyNumber: item.keyNumber,
                    amount: item.amount,
                    note: item.note,
                    billingAddress:
                    billingAddress,
                    customerAddress: ownUser,
                    customerImgDataURI: usrImgBase64
                };

                mapping = post("/locksystems/" + item.locksystemId + "/createAuthorization", req);
            } else {
                const req = {
                    keyNumber: item.keyNumber,
                    amount: item.amount,
                    note: item.note,
                    frontImage: item.frontImage,
                    backImage: item.backImage
                };
                mapping = post("/locksystems/" + item.locksystemId + "/createOrderItem", req);
            }
            successWrapper.add(mapping.then(r => requestedItems.push(r)));

        })).then(() => { //this is called when all items have been mapped, does not mean the posts are done yet, that's what the success wrapper is for
            return successWrapper.waitForSuccess(() => {
                const orderReq = {
                    summary: orderSummaryInfo.keycount + " Schlüssel für " + orderSummaryInfo.locksystems.length + " System(e)",
                    shippingInfo: shipping,
                    billingAddress: billingAddress,
                    items: requestedItems,
                    paymentType: selectedPaymentType,
                    payerEmail: payerEmail,
                    type: authorizationOrder ? orderType.authorization : orderType.standard,
                    internalOrderId: internalOrderId
                };

                if (!useBillingAddressForShipping) {
                    orderReq.shippingAddress = shippingAddress;
                }

                return post("/orders", orderReq).then((createdOrder) => {
                    markDirty();
                    showMessage("Bestellung gespeichert. Sie haben eine Mail mit dem Bestätigungscode erhalten.");
                    history.push("/meine-bestellungen/bearbeiten/" + createdOrder.id);
                });
            });
        }).finally(() => {
            setSubmitting(false);
        });
    };

    const paymentTypeOptions = useMemo(() => {
        let options = [...paymentOptions];
        return options.filter(o => !o.right || o.right && ownUser && ownUser.rights.indexOf(o.right) > -1);
    }, [invoicingEnabled, ownUser]);


    return (
        <div>
            <BreadCrumb currentTitle="Neue Bestellung"/>

            <form onSubmit={e => {
                e.preventDefault();
                addOrderItem();
            }}>
                <h2>Schlüssel hinzufügen</h2>
                <div className="default">
                    <SelectReact name="locksystemId" label="Schliessanlage" value={currentOrderItem.locksystemId}
                                 required options={locksystemOptions} setter={handleUpdate}/>
                    <SelectReact name="keyNumber" label="Schlüsselnummer" value={currentOrderItem.keyNumber} required
                                 options={locksystemKeyOptions} setter={handleUpdate} canAddNew={true}
                                 formatCreateLabel={(val) => val + " hinzufügen"} onCreateOption={addKeyNumber}
                                 placeholder={"Schlüsselnummer eingeben"}/>
                    <InputType name="amount" label="Bestellanzahl" value={currentOrderItem.amount} type="number" min={1}
                               required={true}
                               setter={handleUpdate}/>
                    <InputTextarea name="note" label="Bemerkung" value={currentOrderItem.note} setter={handleUpdate}/>
                    <InputFile name="frontImage" label="Schlüssel Vorderseite" accept="image/*" maxMBSize="10"
                               setter={handleFile} innerRef={frontViewRef}/>
                    <InputFile name="backImage" label="Schlüssel Rückseite" accept="image/*" maxMBSize="10"
                               setter={handleFile} innerRef={backViewRef}/>
                    <InputText name="calculatedPrice" label="Preis (netto, zzgl. 20 % USt.)"
                               value={currentOrderItem.calculatedPrice} disabled/>
                </div>
                <p className={"additional-info"}>
                    Sollten Sie auch einen oder mehrere Zylinder, oder eine Ersatzsperre benötigen, so schreiben Sie uns
                    die Information ins Bemerkungsfeld – unser Team wird sich umgehend melden.
                </p>
                <button disabled={isNotPossible} type="submit">zur Bestellung hinzufügen</button>
            </form>
            {orderItems.length > 0 &&
                <Fragment>
                    <div className="data-table mt-3">
                        <h2 className="mb-0">Artikel</h2>
                        <DataTable data={orderItems} columns={columns} noSearch removeTopPadding/>
                    </div>
                    <div className="mt-3">
                        <form onSubmit={e => {
                            e.preventDefault();
                            setSubmitting(true);
                            order();
                        }}>
                            {authorizationEnabled && <Fragment>
                                <h2 className="mb-05">Schlüsselberechtigung</h2>
                                <p className="additional-info">
                                    Die Auswahl "Schlüsselberechtigung" erzeugt ein Ermächtigungs-Dokument zur
                                    Bestellung des Schlüssels bei einem Schlüsseldienst nach freier Wahl des Kunden bzw.
                                    mittels QR-Code über key-direct.at. Der Bestellvorgang ist hiermit abgeschlossen.
                                    Verwendet der Kunde den key-direct.at Einmal-QR-Code, können Sie den weiteren
                                    Bestellablauf nachverfolgen und die Dokumentation bleibt vollständig.
                                </p>
                                <div className="default">
                                    <InputCheckbox name="authorizationOrder" value={authorizationOrder}
                                                   label="Schlüsselberechtigung" style1 big
                                                   setter={(val) => setAuthorizationOrder(val)}/>
                                </div>
                            </Fragment>}

                            <h2 className="mb-05">Interne Bestellnummer</h2>
                            <p className="additional-info">
                                Hier können Sie optional eine interne Bestellnummer / Referenz angeben, zum Beispiel aus
                                Ihrem ERP-System.
                            </p>
                            <div className="default">
                                <InputText name="internalOrderId" label={"Interne Bestellnummer"}
                                           value={internalOrderId} setter={setInternalOrderId} maxLength={50}/>
                            </div>

                            {!authorizationOrder && <Fragment>
                                <h2 className="mb-05">Versand auswählen</h2>
                                <div className="default">
                                    <SelectReact name="versandoption" label="Versandoption" value={selectedShipping}
                                                 required options={shippingSelectOptions}
                                                 setter={(newValue) => setSelectedShipping(newValue)}/>
                                </div>
                            </Fragment>}

                            {(selectedShipping || authorizationOrder) &&
                                <div>
                                    <h2 className="mt-2 mb-05">Rechnungsadresse auswählen</h2>
                                    <p>
                                        Die Adresse wird aus Ihren Stammdaten vorbefüllt. Sie können sie für diese
                                        Bestellung anpassen.
                                        Eine generelle Änderung muss im Profil vorgenommen werden.
                                    </p>
                                    <AddressInput initial={billingAddress}
                                                  setter={setBillingAddress}
                                                  type={useBillingAddressForShipping ? 'BOTH' : 'BILLING'}/>

                                    {!authorizationOrder && <Fragment>
                                        <h2 className="mt-2 mb-05">Bezahlungsart auswählen</h2>
                                        <div className="default mb-1">
                                            <SelectReact name="paymentType" label="Bezahlungsart"
                                                         value={selectedPaymentType}
                                                         required options={paymentTypeOptions}
                                                         setter={setSelectedPaymentType}/>
                                            {selectedPaymentType == "PAYMENT_LINK" &&
                                                <InputText name={"payerEmail"} required={true} value={payerEmail}
                                                           setter={setPayerEmail} label={"Linkempfänger"}/>}
                                        </div>
                                    </Fragment>}

                                    <h2 className="mt-2 mb-05">Lieferadresse angeben</h2>
                                    <div className="default mb-1">
                                        <InputCheckbox label="Lieferadresse entspricht Rechnungsadresse"
                                                       big
                                                       value={useBillingAddressForShipping}
                                                       setter={(newValue) => setUseBillingAddressForShipping(newValue)}/>
                                    </div>
                                    {!useBillingAddressForShipping &&
                                        <AddressInput initial={shippingAddress}
                                                      setter={setShippingAddress}
                                                      type="SHIPPING"
                                                      optionals/>}
                                    <h2 className="mt-1 mb-05">Bestellung abschicken</h2>
                                    <div className="right">
                                        <OrderTotal orderItems={orderItems}
                                                    shipping={availableShipping.find(s => s.id === selectedShipping)}/>
                                    </div>
                                    <div className={"safe-actions mt-1"}>
                                        <Button key="back" theme="secondary-button mr-1 mb-1" width={230}
                                                submitting={submitting} onClick={e => {
                                            e.preventDefault();
                                            window.history.back()
                                        }}>zurück</Button>
                                        <Button key="submit" theme="primary-button mb-1" width={230}
                                                submitting={submitting}>Bestellung abschicken</Button>
                                    </div>
                                    <br/><br/>
                                </div>}

                        </form>
                    </div>
                </Fragment>}
        </div>
    );

};