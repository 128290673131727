import {Edit} from "./Edit";
import {useHistory, useParams} from "react-router-dom";
import {put} from "../../js/api";
import {markDirty, showMessage} from "../../js/main";
import {Fragment, useEffect, useState} from "react";

export const UpdateShipping = ({shippings}) => {

    const history = useHistory();
    const {shippingId} = useParams();

    const [selectedShipping, setSelectedShipping] = useState();

    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (shippings) {
            setSelectedShipping(shippings.find(e => e.id === parseInt(shippingId)));
        }
    }, [shippings]); // eslint-disable-line react-hooks/exhaustive-deps

    const updateShipping = (shippingInfo) => {
        setSubmitting(true);
        put("/shipping/" + shippingInfo.id, shippingInfo).then(() => {
            markDirty();
            showMessage("Versandoption wurde aktualisiert.");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Fragment>
            {selectedShipping && <Edit initialShippingInfo={selectedShipping} saveAction={updateShipping} submitting={submitting}/>}
        </Fragment>
    );

};