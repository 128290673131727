/*
 *  ~~~~~ Copyright by inndevs GmbH / DDC Engineering GmbH ~~~~~
 *
 *               Your favorite programmers ;-)
 *
 * E-Mail:   hannes@inndevs.com
 *           dominic@ddc-engineering.at
 * Web:      https://inndevs.com
 *           https://ddc-engineering.at
 * Date:     2021-05-16
 *
 *   ________________________________________
 * [[ MESS WITH THE BEST - DiE LIKE THE REST ]]
 *   ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
 */

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
import {BrowserRouter as Router} from "react-router-dom";
import {putGermanVocabulary} from "./js/translate";

Amplify.configure(awsExports);

Amplify.configure({
    API: {
        endpoints: [
            {
                name: "key-direct-api",
                endpoint: "https://api.key-direct.at/v1"
                //endpoint: "http://localhost:3001"
                //endpoint: "https://sly1fh02h0.execute-api.eu-central-1.amazonaws.com/v1"
            },{
                name: "local-test",
                endpoint: "http://localhost:3001"
            }
        ]
    }
});

putGermanVocabulary();

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App/>
        </Router>
    </React.StrictMode>,
    document.getElementById("root")
);
