import {Route, Switch, useHistory} from "react-router-dom";
import {Edit} from "../view/locksystem/Edit.js";
import {Overview} from "../view/myLocksystems/Overview";
import {get, post} from "../js/api";
import {addDirtyListener, markDirty, showMessage} from "../js/main";
import {useEffect, useState} from "react";
import {UpdateLocksystem} from "../view/myLocksystems/UpdateLocksystem";
import {getKeysCache} from "../component/func/KeyCache";

export const MyLocksystems = () => {

    const history = useHistory();

    const [locksystems, setLocksystems] = useState(null);

    const [keys, setKeys] = useState(null);

    const [submitting, setSubmitting] = useState(false);

    const init = () => {
        get("/locksystems").then((response) => {
            setLocksystems(response);
        });
        getKeysCache().then(resp => setKeys(resp));
    }
    useEffect(() => {
        init();
        addDirtyListener(init);
    }, [])

    return (
        <Switch>
            <Route exact path="/meine-schliessanlagen">
                <Overview locksystems={locksystems} keys={keys}/>
            </Route>
            <Route exact path="/meine-schliessanlagen/edit/:locksystemId">
                <UpdateLocksystem locksystems={locksystems} submitting={submitting} setSubmitting={setSubmitting}/>
            </Route>
        </Switch>
    );

};