
import {ReactComponent as IconFile} from "../../img/icon/upload.svg";
import {verify} from "./InputFile";
import {useRef, useState} from "react";
import {showError} from "../../js/main";
export const InputImageFancy = ({name, label, setter, maxMBSize = -1, required = false, disabled = false, concatId = null, onClick=(e) => {}, uniqueRef = null}) => {

    const identifier = concatId ? name + concatId : name;
    const inputTagRef = useRef();
    const fileChanged = useRef(() => {});
    const [dataUrl, setDataUrl] = useState();
    const fileSelected = (e, name) => {
        verify("image/*", maxMBSize, e).then(f => {
            if(uniqueRef){
                //if file is changed we have to remove the old reference, does nothing on first call;
                fileChanged.current();

                if(f) {
                    const name = f.name;
                    if (uniqueRef.current.has(name)) {
                        showError("Die gleiche Datei kann nicht doppelt gewählt werden.")
                        inputTagRef.current.value = null;
                        setDataUrl(null);
                        return null;
                    }

                    //this gets called if the file is changed again
                    fileChanged.current = () => {
                        uniqueRef.current.delete(name);
                    }
                    uniqueRef.current.add(name);
                }else{
                    fileChanged.current = () =>{};
                }
            }
            return f;
        }).then((f)=>{
            setter(f, name);
            if(f){
                let reader = new FileReader();
                reader.onload = (e) => {
                    setDataUrl(e.target.result);
                }
                reader.readAsDataURL(f);
            }else{
                setDataUrl(undefined);
            }
        });
    }

    const handleClick = (e) =>{

    }
    return (
        <div className="form-input" onClick={onClick}>
            {label &&
                <label htmlFor={identifier}>
                    {label} {required && <span className="primary-link bold">*</span>}
                </label>}
            <div className="image-input-container">
                <label htmlFor={identifier} onClick={handleClick} className="image-input">
                    {!dataUrl && <>
                        <IconFile className="file-svg file-svg-center"/>
                    </>}
                    {dataUrl && <img src={dataUrl} className="preview-img"/> }
                    <h3>Datei hochladen</h3>
                    <input id={identifier}
                           type="file"
                           name={name}
                           className={"hide"}
                           onChange={e => fileSelected(e, name)}
                           disabled={disabled}
                           required={required}
                           accept={"image/*"}
                           ref={inputTagRef}/>
                </label>
        </div>
    </div>)
}