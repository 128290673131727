export const genderOptions = [
    {value: "MALE", label:"männlich"},
    {value: "FEMALE", label: "weiblich"},
    {value: "DIVERSE", label: "divers"}
];

export const groupOptions = [
    {value: "admins", label:"Administrator"},
    {value: null, label:"Kunde"},
];

export const rightOptions = [
    {value: "LOCKSYSTEM_UPLOAD", label:"Schließplan PDF"},
    {value: "LOCKSYSTEM_LINK", label: "Schließplan online"},
    {value: "KEY_MANAGEMENT", label:"Schlüsselverwaltung"},
    {value: "AUTHORIZATION", label:"Externe Schlüsselberechtigung"},
    {value: "INVOICING", label:"Bestellung auf Rechnung"}
]

//this right is not a payment options somebody gets to choose
export const paymentTypeDirect = {value:"DIRECT", label: "Bezahlung online", right:"nonvisible"};

export const paymentOptions = [
    {value:"STANDARD", label: "Standard (Bezahlung bei Abholung)"},
    {value:"PAYMENT_LINK", label: "Bezahllink per Mail (Vorauskasse)"},
    {value:"INVOICE", label: "Auf Rechnung", right: "INVOICING"},
    paymentTypeDirect
]

export const orderType = {
    standard: "STANDARD",
    authorization: "AUTHORIZATION",
    request: "REQUEST"
}

export const yearsOptions = [];

for (let i = new Date().getFullYear(); i >= 2009; i--) {
    yearsOptions.push({value:i, label:i});
}