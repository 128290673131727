import {I18n} from "aws-amplify";
import {Translations} from "@aws-amplify/ui-components";

export const putGermanVocabulary = () => {
    I18n.setLanguage("de");

    const dict = {
        "de": {
            "Sign in to your account": "Login",
            "Sign In": "Anmelden",
            "Username *": "Benutzername *",
            "Password *": "Passwort *",
            "Enter your username": "Geben Sie Ihren Benutzernamen ein",
            "Enter your password": "Geben Sie Ihr Passwort ein",
            "Forgot your password?": "Passwort vergessen?",
            "Reset password": "Passwort zurücksetzen",
            "No account?": "Kein Benutzerkonto?",
            "Create account": "Benutzerkonto erstellen",
            "Username cannot be empty": "Username darf nicht leer sein.",
            "Password cannot be empty": "Passwort darf nicht leer sein.",
            "Incorrect username or password.": "Benutzername oder Passwort falsch.",
            "Reset your password": "Passwort zurücksetzen",
            "Back to Sign In": "Zurück zum Login",
            "Send Code": "Code zusenden",
            "User does not exist.": "Benutzer existiert nicht.",
            "Sign Out": "Abmelden",
            "Change Password": "Passwort ändern",
            "New password": "Neues Passwort",
            "Email Address": "E-Mail",
            [Translations.NEW_PASSWORD_PLACEHOLDER]: "Geben Sie Ihr neues Passwort ein",
            [Translations.EMAIL_PLACEHOLDER]: "Geben Sie Ihre E-Mail-Adresse ein",
            "Change": "Ändern",
            "Verification code": "Bestätigungscode",
            "Enter code": "Code eingeben",
            "Submit": "Senden",
            "Account recovery requires verified contact information": "Verifizieren Sie bitte den Account",
            "Skip": "Überspringen",
            "Verify": "Verifizieren",
        }
    }

    I18n.putVocabularies(dict);
}
